import JSZip from 'jszip';

export const getValueByNumber = (num: number, obj: { [key: string]: string }, from: number, lastVisible: string|null): { "key": number, "value": string | null } => {
  //sort keys first
  const keys = Object.keys(obj).map(Number).sort((a, b) => a - b);
  for (let i = keys.length - 1; i >= 0; i--) {
    if (keys[i] < num) {
      return { "key": keys[i], "value": obj[keys[i]] }
    }
  }
  return { "key": from, "value": lastVisible };
}


type PaginationLink = {
  url: string | null;
  label: string;
  active: boolean;
  page: number;
};

export const generatePaginationLinks = (page: number, lastVisible: string | null): PaginationLink[] => {

  // alert(page + " " + lastVisible)

  let previous = page > 1 ? page - 1 : 1;
  let next = lastVisible ? page + 1 : 1;

  let previousBaseIcon = { "url": '', "label": "&laquo; Previous", "active": false, "page": previous };
  let previousBase = { "url": `/?page=${previous}`, "label": `${previous}`, "active": false, "page": previous };
  let currentBase = { "url": `/?page=${page}`, "label": `${page}`, "active": true, "page": page };
  let nextBaseIcon = { "url": `/?page=${next}`, "label": "Next &raquo;", "active": false, "page": next };
  let nextBase = { "url": `/?page=${next}`, "label": `${next}`, "active": false, "page": next };

  //send page-1
  const getPrevious = (value: number): PaginationLink[] => {
    let prev = [previousBaseIcon]
    if (value < 1) {
      return prev
    }
    else {
      let arr = new Array(value).fill(0);
      let counter = value - 3;
      arr.map((item, index) => {
        if (index > 2) {
          return
        }

        if (value < 2) {
          counter = value;
        }
        else if (value < 3) {
          counter = index + 1
        }
        else {
          counter++
        }
        let p_Obj = { "url": `/?page=${counter}`, "label": `${counter}`, "active": false, "page": counter }
        prev.push(p_Obj)
      })
      return prev
    }
  }

  const getNext = (value: number): PaginationLink[] => {
    let nextArr: PaginationLink[] = [];

    if (lastVisible) {
      for (let index = 1; index < 4; index++) {
        nextArr.push({ "url": `/?page=${value + index}`, "label": `${value + index}`, "active": false, "page": value + index });
      }
      nextArr.push()
    }
    return nextArr
  }

  if (page > 1 && lastVisible) {
    return [
      ...getPrevious(page - 1),
      currentBase,
      ...getNext(page),
      nextBaseIcon,
    ];
  } else if (page > 1) {
    return [
      ...getPrevious(page - 1),
      currentBase,
      ...getNext(page),
      nextBaseIcon
    ];
  } else if (lastVisible) {
    return [
      ...getPrevious(page - 1),
      currentBase,
      ...getNext(page),
      nextBaseIcon,
    ];
  } else {
    return [
      ...getPrevious(page - 1),
      currentBase,
    ];
  }
}

export const downloadFile = (imageUrl: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', imageUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response as Blob;
        resolve(blob);
      } else {
        reject(new Error(`Failed to download image. Status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error(`Network error while trying to download image.`));
    };

    xhr.send();
  });
};

// Helper function to get a unique file name by appending an index
export const getUniqueFileName = (zip: JSZip, baseFileName: string): string => {
  let fileName = baseFileName;
  let index = 1;

  // Check if the file with the same name already exists in the zip
  while (zip.file(fileName)) {
    fileName = `${baseFileName.replace('.png', `_${index}.png`)}`;
    index++;
  }

  return fileName;
};