import {Column} from 'react-table'
import {AppInfoCell} from './AppInfoCell'
import {AppLastLoginCell} from './AppLastLoginCell'
import {AppTwoStepsCell} from './AppTwoStepsCell'
import {AppActionsCell} from './AppActionsCell'
import {AppSelectionCell} from './AppSelectionCell'
import {AppCustomHeader} from './AppCustomHeader'
import {AppSelectionHeader} from './AppSelectionHeader'
import {App} from '../../core/_models'

const appsColumns: ReadonlyArray<Column<App>> = [
  // {
  //   Header: (props) => <AppSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <AppSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <AppCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <AppInfoCell app={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <AppCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
  //   accessor: 'role',
  // },
  // {
  //   Header: (props) => (
  //     <AppCustomHeader tableProps={props} title='Date' className='min-w-125px' />
  //   ),
  //   id: 'dateCreated',
  //   Cell: ({...props}) => <AppLastLoginCell dateCreated={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <AppCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <AppTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <AppCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <AppCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <AppActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {appsColumns}
