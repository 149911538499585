import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {TemplatesListWrapper} from './templates-list/TemplatesList'

const templatesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Template Management',
    path: '/apps/template-management/templates',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TemplatesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='templates'
          element={
            <>
              <PageTitle breadcrumbs={templatesBreadcrumbs}>Templates list</PageTitle>
              <TemplatesListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/template-management/templates' />} />
    </Routes>
  )
}

export default TemplatesPage
