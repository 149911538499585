import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { convertArrToSelect, copyToClipboard, downloadFile, getDateTime, navigateToModal, stringToDate, dateDownloadFormat } from '../../../../../../jsFunctions';
import { ContentsListLoading } from '../components/loading/ContentsListLoading';
import { useListView } from '../core/ListViewProvider';
import { useQueryResponse } from '../core/QueryResponseProvider';
import { Content, initialContent } from '../core/_models';
import { createContent, updateContent, uploadAndUpdate } from '../core/_requests';

type Props = {
  isContentLoading: boolean
  content: Content
}

const editContentSchema = Yup.object().shape({
  // name: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Name is required')
})

interface DropdownOptions {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'content', label: 'content', color: '#00B8D9', isFixed: true },
  { value: 'client', label: 'client', color: '#00B8D9', isFixed: true },
  { value: 'testing', label: 'testing', color: '#00B8D9', isFixed: true },
  { value: 'SAYCHEESE', label: 'SAYCHEESE', color: 'red', isDisabled: true },
];

// const downloadFile = (url: string, filename: string) => {
//   const link = document.createElement('a');
//   link.href = url;
//   link.download = filename;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };


const ContentEditModalForm: FC<Props> = ({ content, isContentLoading }) => {
  const { setItemIdForUpdate, setSessionIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const [contentForEdit] = useState<Content>({
    ...content,
    avatar: content.avatar || initialContent.avatar,
    info: content.info || initialContent.info,
    name: content.name || initialContent.name,
    tags: content.tags ? convertArrToSelect(content.tags) : dropdownOptions
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const [avatar, setAvatar] = useState<any>(contentForEdit.avatar);

  const [dimensions, setDimensions] = useState<string>("");
  const [size, setSize] = useState<number>(0);


  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    // const { target } = event;
    // const img = new Image();

    const ImageName = event.target.value.split('\\')[2];
    const Image = event.target.value;
    console.log('Imageon Kevel: ', Image); // Image on Kevel
    console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
    console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel
    // console.log('event current Target files: ', event.target?.files?[0].path);

    if (event?.target?.files?.[0]!) {
      setAvatar({ display: URL.createObjectURL(event?.target?.files?.[0]), upload: event?.target?.files?.[0] });
    }
  };

  useEffect(() => {
    const image = new Image();
    image.src = contentForEdit.original;

    image.onload = () => {
      setDimensions(image.width + " by " + image.height);
      fetch(image.src)
        .then(response => response.headers.get('content-length'))
        .then(size => {
          if (size) {
            const sizeInMB: number = parseFloat((parseInt(size) / (1024 * 1024)).toFixed(2)); // Convert bytes to MB with 2 decimal places
            setSize(sizeInMB)
            // Here you can do whatever you need with the image size in KB, such as setting state or logging it
          } else {
            console.error("Error: Could not determine image size.");
          }
        })
        .catch(error => {
          console.error("Error fetching image size:", error);
        });
    };

    image.onerror = () => {
      console.error('Error loading image');
    };
  }, [contentForEdit.avatar]);

  useEffect(() => {
    const loadMediaDimensionsAndSize = (src: string, type: string) => {
      if (type === 'image') {
        const image = new Image();
        image.src = src;
  
        image.onload = () => {
          setDimensions(image.width + " by " + image.height);
          fetch(src)
            .then(response => response.headers.get('content-length'))
            .then(size => {
              if (size) {
                const sizeInMB = parseFloat((parseInt(size) / (1024 * 1024)).toFixed(2)); // Convert bytes to MB with 2 decimal places
                setSize(sizeInMB);
              } else {
                console.error("Error: Could not determine image size.");
              }
            })
            .catch(error => {
              console.error("Error fetching image size:", error);
            });
        };
  
        image.onerror = () => {
          console.error('Error loading image');
        };
      } else if (type === 'video') {
        const video = document.createElement('video');
        video.src = src;
  
        video.onloadedmetadata = () => {
          setDimensions(video.videoWidth + " by " + video.videoHeight);
          fetch(src)
            .then(response => response.headers.get('content-length'))
            .then(size => {
              if (size) {
                const sizeInMB = parseFloat((parseInt(size) / (1024 * 1024)).toFixed(2)); // Convert bytes to MB with 2 decimal places
                setSize(sizeInMB);
              } else {
                console.error("Error: Could not determine video size.");
              }
            })
            .catch(error => {
              console.error("Error fetching video size:", error);
            });
        };
  
        video.onerror = () => {
          console.error('Error loading video');
        };
      }
    };
  
    if (contentForEdit.video) {
      loadMediaDimensionsAndSize(contentForEdit.video, 'video');
    }
  }, [contentForEdit.video]);
  


  const formik = useFormik({
    initialValues: contentForEdit,
    validationSchema: editContentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {

          await updateContent({ ...values, isLocked: content.isDooh !== values.isDooh ? true : false })

          if (avatar.upload && values.id) {
            uploadAndUpdate(values.id, avatar.upload, values.id)
          }
        } else {
          await createContent({ ...values, avatar: avatar.upload })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_content_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_content_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_content_header'
          data-kt-scroll-wrappers='#kt_modal_add_content_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Content Id</label>
            {/* end::Label */}

            {/* begin::Input */}
            <div className='input-group' style={{ zIndex: 0 }}>
              <input
                placeholder='id'
                aria-disabled
                disabled={true}
                {...formik.getFieldProps('id')}
                type='text'
                name='id'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.id && formik.errors.id },
                  { 'is-valid': formik.touched.id && !formik.errors.id }
                )}
                autoComplete='off'
              />
              <button
                className='btn btn-primary'
                type='button'
                disabled={formik.isSubmitting || isContentLoading}
                onClick={() => {
                  copyToClipboard(formik.values.id)
                }}
              >
                Copy
              </button>
            </div>
            {/* end::Input */}

            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id}</span>
                </div>
              </div>
            )}
          </div>

          {/* begin::Input group */}
          <div className='fv-row mb-7 position-relative'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Content</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {contentForEdit.video ? (
                <>
                  <video
                    className='content-input-wrapper'
                    src={contentForEdit.video}
                    controls
                    controlsList="nodownload"
                    style={{
                      width: '225px',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '10px 10px 10px 10px',
                    }}
                  />
                  <a
                    onClick={() => {
                      // downloadFile(
                      //   contentForEdit.video!,
                      //   `${dateDownloadFormat(contentForEdit.activations[0].data.startDate.seconds * 1000)}-${contentForEdit.activations[0].label}-${contentForEdit.templateType}-${contentForEdit.sessionNumber}.mp4`
                      // );
                      downloadFile(contentForEdit.video, dateDownloadFormat(contentForEdit.activations[0].data.startDate.seconds * 1000) + "-" + contentForEdit.activations[0].label + "-" + contentForEdit.templateType + "-" + contentForEdit.sessionNumber, true)
                    }}
                    download={`${contentForEdit.id}.mp4`}
                    className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute top-0 end-0 m-3 mt-5'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Download Video'
                  >
                    <i className='bi bi-download'></i>
                  </a>
                </>
              ) : (
                <>
                  <img
                    className='image-input-wrapper'
                    src={contentForEdit.avatar}
                    alt='Image Alt Text'
                    style={{
                      width: '125px',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '10px 10px 10px 10px',
                    }}
                  />
                  <a
                    onClick={() => {
                      downloadFile(contentForEdit.original, dateDownloadFormat(contentForEdit.activations[0].data.startDate.seconds * 1000) + "-" + contentForEdit.activations[0].label + "-" + contentForEdit.templateType + "-" + contentForEdit.sessionNumber)
                      // downloadFile(
                      //   contentForEdit.original,
                      //   `${dateDownloadFormat(contentForEdit.activations[0].data.startDate.seconds * 1000)}-${contentForEdit.activations[0].label}-${contentForEdit.templateType}-${contentForEdit.sessionNumber}.jpg`
                      // );
                    }}
                    download={`${contentForEdit.id}.jpg`}
                    className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute bottom-0 end-0 m-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Download Image'
                  >
                    <i className='bi bi-download'></i>
                  </a>
                </>
              )}
            </div>
            {/* end::Image input */}
          </div>
          {/* end::Input group */}




          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Dimensions</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Dimensions'
              value={dimensions}
              type='text'
              name='dimensions'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.dimensions && formik.errors.dimensions },
                {
                  'is-valid': formik.touched.dimensions && !formik.errors.dimensions,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.dimensions && formik.errors.dimensions && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dimensions}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Size (Mbs)</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Size'
              value={size}
              type='text'
              name='size'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.size && formik.errors.size },
                {
                  'is-valid': formik.touched.size && !formik.errors.size,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.size && formik.errors.size && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.size}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Creation Date</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Creation Date'
              {...formik.getFieldProps('dateCreated')}
              type='text'
              name='dateCreated'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.dateCreated && formik.errors.dateCreated },
                {
                  'is-valid': formik.touched.dateCreated && !formik.errors.dateCreated,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.dateCreated && formik.errors.dateCreated && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dateCreated}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Last Modified</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Creation Date'
              {...formik.getFieldProps('updatedOn')}
              type='text'
              name='updatedOn'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.updatedOn && formik.errors.updatedOn },
                {
                  'is-valid': formik.touched.updatedOn && !formik.errors.updatedOn,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.updatedOn && formik.errors.updatedOn && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.updatedOn}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Switch */}
          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6'>Marketing Consent</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                {...formik.getFieldProps('consent')}
                type='checkbox'
                id='consent'
                checked={formik.values.consent}
                onChange={() => formik.setFieldValue('consent', !formik.values.consent)}
                disabled={isContentLoading}
              />
            </div>
          </div>
          {/* end::Switch */}

          {/* begin::Switch */}
          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6'>Consent (DOOH)</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                {...formik.getFieldProps('isDooh')}
                type='checkbox'
                id='isDooh'
                checked={formik.values.isDooh}
                onChange={() => formik.setFieldValue('isDooh', !formik.values.isDooh)}
                disabled={isContentLoading || !formik.values.dooh}
              />
            </div>
          </div>
          {/* end::Switch */}

          {
            contentForEdit.activations.length ? (
              <div className='fv-row mb-3' >
                {/* begin::Label */}
                <div className='card-header border-0 mb-7'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Activation</h3>
                </div>
                {/* end::Label */}
                <a onClick={() => navigateToModal("event", contentForEdit.activations[0].id)} href='#' className='d-flex align-items-center mb-7'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5'>
                    <img src={contentForEdit.activations[0].avatar ? contentForEdit.activations[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <div className='text-dark fw-bold text-hover-primary fs-6'>
                      {contentForEdit.activations[0].label}
                    </div>
                    <span className='text-muted d-block fw-semibold fs-6'>
                      {getDateTime(contentForEdit.activations[0].data.startDate.seconds * 1000)}
                    </span>
                    <span className='text-muted d-block fw-semibold mt-3'>
                      DOOH: {contentForEdit.activations[0].data.isDooh ? contentForEdit.activations[0].data.dooh : 'Disabled'}
                    </span>
                    <span className='text-muted d-block fw-semibold mt-3'>
                      SW: {contentForEdit.activations[0].data.isMicrosite ? contentForEdit.activations[0].data.microsite : 'Disabled'}
                    </span>
                  </div>

                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(contentForEdit.activations[0].value);
                    }}
                  >
                    Copy
                  </button>
                </a>
              </div>
            ) : null
          }


          {
            contentForEdit.collections.length ? (
              <div className='fv-row mb-3'>
                {/* begin::Label */}
                <div className='card-header border-0 mb-5'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Collection</h3>
                </div>
                {/* end::Label */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5' onClick={() => navigateToModal("campaign", contentForEdit.collections[0].id)}>
                    <img src={contentForEdit.collections[0].avatar ? contentForEdit.collections[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {contentForEdit.collections[0].label}
                    </a>
                    <span className='text-muted d-block fw-semibold'>{getDateTime(contentForEdit.collections[0].data.dateCreated.seconds * 1000)}</span>
                  </div>
                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(contentForEdit.collections[0].value);
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
            ) : null
          }

          {
            contentForEdit.memoryMakers.length ?
              <div className='fv-row mb-3' >
                {/* begin::Label */}
                <div className='card-header border-0 mb-5'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Memory Maker</h3>
                </div>
                {/* end::Label */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5' onClick={() => navigateToModal("users", contentForEdit.memoryMakers[0].id)}>
                    <img src={contentForEdit.memoryMakers[0].avatar ? contentForEdit.memoryMakers[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {contentForEdit.memoryMakers[0].label}
                    </a>
                    <span className='text-muted d-block fw-semibold'>{contentForEdit.memoryMakers[0].data.email}</span>
                  </div>
                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(contentForEdit.memoryMakers[0].value);
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
              :
              null
          }



          {/* begin::Clickable div */}
          <a
            className={clsx(
              'fv-row mb-7',
              'position-relative',
              'clickable', // Add this class for styling the clickable div
            )}
            href='#'
            onClick={() => {
              setSessionIdForUpdate && setSessionIdForUpdate(contentForEdit.session);
              setItemIdForUpdate(null);
            }}
          >
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Session Number</label>
            {/* end::Label */}

            {/* Display session number directly without the input field */}
            <div className='form-control form-control-solid'>
              {contentForEdit.sessionNumber}
            </div>
          </a>
          {/* end::Clickable div */}

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              QR Codes: <span className='fs-6 text-muted fw-light'>({contentForEdit.qrCodes ? contentForEdit.qrCodes.length : 0} codes)</span>
            </label>
            <div>
              {contentForEdit.qrCodes?.map((item, index) => (
                <div key={index} className='badge badge-light-info fw-bolder m-2 p-2'>
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              Mails: <span className='fs-6 text-muted fw-light'>({contentForEdit.emails ? contentForEdit.emails.length : 0} mails)</span>
            </label>
            <div>
              {contentForEdit.emails?.map((item, index) => (
                <div key={index} className='badge badge-light-info fw-bolder m-2 p-2'>
                  {item}
                </div>
              ))}
            </div>
          </div>



          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Prints</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='0'
              value={contentForEdit.prints}
              type='text'
              name='prints'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              disabled={true}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {
            contentForEdit.templates.length ? (
              <a className='fv-row mb-3' href='#' onClick={() => navigateToModal("template", contentForEdit.templates[0].id)} >
                {/* begin::Label */}
                <div className='card-header border-0 mb-5'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Template</h3>
                </div>
                {/* end::Label */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5' >
                    <img src={contentForEdit.templates[0].avatar ? contentForEdit.templates[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {contentForEdit.templates[0].label}
                    </a>
                    <span className='text-muted d-block fw-semibold'>{contentForEdit.templates[0].data.type}</span>
                  </div>
                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(contentForEdit.templates[0].value);
                    }}
                  >
                    Copy
                  </button>
                </div>
              </a>
            ) : null
          }


        </div>
        {/* end::Scroll */}



        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-contents-modal-action='cancel'
            disabled={formik.isSubmitting || isContentLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-contents-modal-action='submit'
            disabled={isContentLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isContentLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form >
      {(formik.isSubmitting || isContentLoading) && <ContentsListLoading />
      }
    </>
  )
}

export { ContentEditModalForm };

