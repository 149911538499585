import { Column } from 'react-table'
import { EventInfoCell } from './EventInfoCell'
import { EventLastLoginCell } from './EventLastLoginCell'
import { EventTwoStepsCell } from './EventTwoStepsCell'
import { EventActionsCell } from './EventActionsCell'
import { EventSelectionCell } from './EventSelectionCell'
import { EventCustomHeader } from './EventCustomHeader'
import { EventSelectionHeader } from './EventSelectionHeader'
import { ParentInfoCell } from './ParentInfoCell'
import { Event } from '../../core/_models'
import { EventEnabledDisabled } from './EventEnabledDisabled'
import { isURL } from '../../../../../../../jsFunctions'

const eventsColumns: ReadonlyArray<Column<Event>> = [
  {
    Header: (props) => <EventSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <EventSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Project #' className='min-w-125px' />
    ),
    accessor: 'projectNo',
  },
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Activation Avatar' className='min-w-125px' />,
    id: 'composite_avatar',
    Cell: ({ ...props }) => <EventInfoCell event={props.data[props.row.index]} avatar={true} />,
  },
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Activation Title' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <EventInfoCell event={props.data[props.row.index]} avatar={false} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Collections' className='min-w-125px' />
    ),
    id: 'composite_associated_collections',
    Cell: ({ ...props }) => <ParentInfoCell parent={props.data[props.row.index].associatedCollections} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Analytics' className='min-w-125px' />
    ),
    id: 'isAnalytics',
    Cell: ({ ...props }) => <EventEnabledDisabled value={props.data[props.row.index].isAnalytics} url={props.data[props.row.index].embedCode} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
    ),
    id: 'startDate',
    Cell: ({ ...props }) => <EventLastLoginCell last_login={props.data[props.row.index].startDate} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Client' className='min-w-125px' />
    ),
    id: 'composite_events',
    Cell: ({ ...props }) => <ParentInfoCell parent={props.data[props.row.index].parent} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Shared Clients' className='min-w-125px' />
    ),
    id: 'composite_parent',
    Cell: ({ ...props }) => <ParentInfoCell parent={props.data[props.row.index].clients} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Templates' className='min-w-125px' />
    ),
    id: 'composite_template',
    accessor: (row) => {
      // Here, you can manually calculate or access the projectNo from the row data
      // For example, if projectNo is a property of the row, you can do:
      return row.templates?.length;

      // Alternatively, you can return a manually inputted value if needed
      // return 'manual-input-value';
    },
  },  
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Storywall' className='min-w-125px' />
    ),
    id: 'isMicrosite',
    Cell: ({ ...props }) => <EventEnabledDisabled value={props.data[props.row.index].isMicrosite} url={props.data[props.row.index].micrositeBeta} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='DOOH' className='min-w-125px' />
    ),
    id: 'isDooh',
    Cell: ({ ...props }) => <EventEnabledDisabled value={props.data[props.row.index].isDooh} url={props.data[props.row.index].dooh} />,
  },
  // {
  //   Header: (props) => (
  //     <EventCustomHeader tableProps={props} title='Comments' className='min-w-125px' />
  //   ),
  //   id: 'composite_info',
  //   accessor: 'info',
  // },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Creation Date' className='min-w-125px' />
    ),
    id: 'dateCreated',
    Cell: ({ ...props }) => <EventLastLoginCell last_login={props.data[props.row.index].dateCreated} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Last Modified' className='min-w-125px' />
    ),
    id: 'updatedOn',
    Cell: ({ ...props }) => <EventLastLoginCell last_login={props.data[props.row.index].updatedOn} />,
  },
  {
    Header: (props) => (
      <EventCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <EventActionsCell eventName={props.data[props.row.index].name} embedCode={props.data[props.row.index].embedCode} id={props.data[props.row.index].id} />,
  },
]

export { eventsColumns }
