import { Column } from 'react-table'
import { TemplateInfoCell } from './TemplateInfoCell'
import { TemplateLastLoginCell } from './TemplateLastLoginCell'
import { TemplateTwoStepsCell } from './TemplateTwoStepsCell'
import { TemplateActionsCell } from './TemplateActionsCell'
import { TemplateSelectionCell } from './TemplateSelectionCell'
import { TemplateCustomHeader } from './TemplateCustomHeader'
import { TemplateSelectionHeader } from './TemplateSelectionHeader'
import { ParentInfoCell } from './ParentInfoCell'
import { Template } from '../../core/_models'
import { TemplateEnabledDisabled } from './TemplateEnabledDisabled'
import { TemplateType } from './TemplateType'

const templatesColumns: ReadonlyArray<Column<Template>> = [
  {
    Header: (props) => <TemplateSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <TemplateSelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <TemplateCustomHeader tableProps={props} title='Preview' className='min-w-125px' />,
    id: 'composite_preview',
    Cell: ({ ...props }) => <TemplateInfoCell template={props.data[props.row.index]} index={props.row.index} id={props.data[props.row.index].id} />,
  },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Hidden' className='min-w-125px' />
  //   ),
  //   accessor: 'disable',
  // },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Hidden' className='min-w-125px' />
    ),
    id: 'disable',
    Cell: ({ ...props }) => <TemplateEnabledDisabled value={props.data[props.row.index].disable} text={props.data[props.row.index].disable === "true" ? "Yes" : "No"} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Title' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='ID' className='min-w-125px' />
  //   ),
  //   accessor: 'id',
  // },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Creation Date' className='min-w-125px' />
    ),
    id: 'dateCreated',
    Cell: ({ ...props }) => <TemplateLastLoginCell last_login={props.data[props.row.index].dateCreated} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Last Modified' className='min-w-125px' />
    ),
    id: 'updatedOn',
    Cell: ({ ...props }) => <TemplateLastLoginCell last_login={props.data[props.row.index].updatedOn} />,
  },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Dimensions' className='min-w-125px' />
  //   ),
  //   id: 'raw',
  //   Cell: ({ ...props }) => <ParentInfoCell template={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    id: 'composite_type',
    Cell: ({ ...props }) => <TemplateType type={props.data[props.row.index].type} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Upload' className='min-w-125px' />
    ),
    id: 'uploadAllowed',
    Cell: ({ ...props }) => <TemplateEnabledDisabled value={props.data[props.row.index].uploadAllowed} text={props.data[props.row.index].uploadAllowed === "true" ? "Enabled" : "Disabled"} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Storywall' className='min-w-125px' />
    ),
    id: 'microsite',
    Cell: ({ ...props }) => <TemplateEnabledDisabled value={props.data[props.row.index].microsite} text={props.data[props.row.index].microsite === "true" ? "Enabled" : "Disabled"} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='DOOH' className='min-w-125px' />
    ),
    id: 'dooh',
    Cell: ({ ...props }) => <TemplateEnabledDisabled value={props.data[props.row.index].dooh} text={props.data[props.row.index].dooh === "true" ? "Enabled" : "Disabled"} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Print' className='min-w-125px' />
    ),
    id: 'print',
    Cell: ({ ...props }) => <TemplateEnabledDisabled value={props.data[props.row.index].print} text={props.data[props.row.index].print === "true" ? "Enabled" : "Disabled"} />,
  },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Qrs Codes' className='min-w-125px' />
  //   ),
  //   id: 'qrCodes',
  //   Cell: ({ ...props }) => <TemplateTwoStepsCell tags={props.data[props.row.index].tags} />,
  // },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Emails' className='min-w-125px' />
  //   ),
  //   id: 'emails',
  //   Cell: ({ ...props }) => <TemplateTwoStepsCell tags={props.data[props.row.index].emails} />,
  // },

  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Consent' className='min-w-125px' />
  //   ),
  //   accessor: 'consent',
  // },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Photoroom' className='min-w-125px' />
  //   ),
  //   accessor: 'photoroom',
  // },
  // {
  //   Header: (props) => (
  //     <TemplateCustomHeader tableProps={props} title='Mails' className='min-w-125px' />
  //   ),
  //   accessor: 'mails',
  // },
  // {
  //   Header: (props) => <TemplateCustomHeader tableProps={props} title='Clients' className='min-w-125px' />,
  //   accessor: 'parent',
  // },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <TemplateActionsCell id={props.data[props.row.index].id} />,
  },
]

export { templatesColumns }
