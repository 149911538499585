/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import { StepProps } from '../IAppModels'
import { Search } from '../search/Search';
import { AppsListWrapper } from '../../../../../app/modules/apps/tables_modal/apps-list/AppsList';

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const colourOptions: readonly ColourOption[] = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];


const Step1 = ({ data, updateData, hasError }: StepProps) => {

  // alert(JSON.stringify(data))
  
  return (
    // <div className='current' data-kt-search-element='content'
    // // id='kt_header_search_toggle' 
    // >
    //   <div className='fv-row'>
    //     <CampaignsListWrapper hasError={hasError} data={data} updateData={updateData} />
    //   </div>
    // </div>
    <div className='current' data-kt-stepper-element='content'>
      <div style={{'backgroundColor': ''}} className='w-100'>
        <AppsListWrapper hasError={hasError} data={data} updateData={updateData} />
        {/*begin::Form Group */}
        {/* <div className='fv-row mb-10'>
          {!data.appBasic.name && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='name' data-validator='notEmpty' className='fv-help-block'>
                App name is required
              </div>
            </div>
          )}
        </div> */}
    </div>
  </div >

  )
}

export { Step1 }
