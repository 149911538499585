import { useEffect } from 'react'
import { initialQueryState, KTCard } from '../../../../../_metronic/helpers'
import { StepProps } from '../../../../../_metronic/partials/modals/create-event-stepper/IAppModels'
import { AppsListHeader } from './components/header/AppsListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { AppsTable } from './table/AppsTable'
import { useQueryRequest } from './core/QueryRequestProvider'

const AppsList = ({ data, updateData, hasError}: StepProps) => {
  const {updateState, state} = useQueryRequest()
  useEffect(() => {
    updateState({
      type: data.type
    })
  }, [])
  return (
    <>
      <KTCard className='shadow-none'>
        <AppsListHeader />
        <AppsTable hasError={hasError} data={data} updateData={updateData}/>
      </KTCard>
    </>
  )
}

const AppsListWrapper = ({ data, updateData, hasError}: StepProps) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AppsList data={data} hasError={hasError}  updateData={updateData} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AppsListWrapper }

