import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { KTCard, KTSVG, QUERIES } from '../../../../../_metronic/helpers';
import { EventsListHeader } from './components/header/EventsListHeader';
import { ListViewProvider, useListView } from './core/ListViewProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { QueryResponseProvider, useQueryResponse } from './core/QueryResponseProvider';
import { archiveEvent, archiveSelectedEvents, prepareForDeletion } from './core/_requests';
import { EventEditModal } from './event-edit-modal/EventEditModal';
import { EventsTable } from './table/EventsTable';

const EventsList = () => {
  const { itemIdForUpdate, itemIdForDelete, setItemIdForDelete, flagForDelete, setFlagForDelete, selected, clearSelected, setItemIdForUpdate } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const [warningMessage, setWarningMessage] = useState(Object);

  const deleteSelectedItems = useMutation(() => archiveSelectedEvents(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CONTENTS_LIST}-${query}`])
      setFlagForDelete?.(false)
      clearSelected()
    },
  })

  const deleteItem = useMutation(() => archiveEvent(itemIdForDelete), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      setItemIdForDelete(null)
    },
  })

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const edit = queryParams.get('edit');

  useEffect(() => {

    if (edit) {
      setItemIdForUpdate(edit);
    }

    const fetchData = async () => {
      if (itemIdForDelete) {
        try {
          const info = await prepareForDeletion(itemIdForDelete);
          if (info) {
            setWarningMessage(info);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [itemIdForDelete]);



  return (
    <>
      <KTCard>
        <EventsListHeader />
        <EventsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <EventEditModal />}
      {(itemIdForDelete || flagForDelete) && warningMessage &&
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div
                  className='modal fade show d-block'
                  id='kt_modal_add_user'
                  role='dialog'
                  tabIndex={-1}
                  aria-modal='true'>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Archive Activation</h5>
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setFlagForDelete?.(false)
                            setItemIdForDelete(null)
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                          />
                        </div>
                      </div>


                      <div className="modal-body">
                        Press confirm to delete.
                        <div style={{ marginTop: 15 }}>
                          {'\n\n'}
                          {flagForDelete && "Note: You are performing combined delete operation. This might cause oversight."}
                          {!flagForDelete && (
                            <div className="modal-body">
                              Note:
                              <ul style={{ listStyleType: 'none', paddingInlineStart: 0, marginTop: '5px' }}>
                                <li>{`- ${warningMessage.collections} Collections are associated with this activation.`}</li>
                                <li>{`- ${warningMessage.contents} Contents are created for this activation.`}</li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setFlagForDelete?.(false)
                            setItemIdForDelete(null)
                          }}
                        >
                          Cancel
                        </button>
                        <button type="button" className="btn btn-primary"
                          onClick={async () => {
                            if (itemIdForDelete) {
                              deleteItem.mutateAsync()
                            }
                            else if (selected.length && flagForDelete) {
                              await deleteSelectedItems.mutateAsync()
                            }
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'
            onClick={() => {
              setFlagForDelete?.(false)
              setItemIdForDelete(null)
            }}
          />
          {/* end::Modal Backdrop */}
        </>
      }
    </>
  )
}

const EventsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EventsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { EventsListWrapper };

