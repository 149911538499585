import { useQueryClient, useMutation } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { archiveSelectedEvents } from '../../core/_requests'

const EventsListGrouping = () => {
  const { selected, clearSelected, setFlagForDelete } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()


  return (
    <div className='d-flex justify-content-end align-items-center'>

      {selected.length > 0 && (
        <button
          type='button'
          className='btn me-2 svg-icon svg-icon-1 bold svg-icon-danger'
          onClick={() => clearSelected()}
        >
          Clear
        </button>
      )}

      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => { setFlagForDelete?.(true)}}
      >
        Archive Selected
      </button>
    </div>
  )
}

export { EventsListGrouping }
