/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';

import { toAbsoluteUrl } from '../../../../helpers';
import { StepProps } from '../IAppModels';

const Step4 = ({ data, updateData, hasError }: StepProps) => {
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [avatar, setAvatar] = useState<any>({display: blankImg});
  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    // const { target } = event;
    // const img = new Image();
    const ImageName = event.target.value.split('\\')[2];
    const Image = event.target.value;
    console.log('Imageon Kevel: ', Image); // Image on Kevel
    console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
    console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel
    // console.log('event current Target files: ', event.target?.files?[0].path);

    if(event?.target?.files?.[0]!) {
      setAvatar({display: URL.createObjectURL(event?.target?.files?.[0]), upload: event?.target?.files?.[0]});  
      updateData({avatar: event?.target?.files?.[0]})
      Promise.resolve(true)
    }
    else {
      Promise.resolve(false)
    }
  };

  const handleRemove = () => {
    setAvatar({display: null, upload: null});
    updateData({avatar: null})
  }
  
  return (
    <>
      {/*begin::Step 4 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              // className='image-input image-input-outline'
              className='image-input'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${avatar.display}')` }}
              />
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon  btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input onChange={handleChange}
                  type='file' name='avatar' accept='.png, .jpg, .jpeg' />
                {/* <input onChange={handleChange} type='hidden' name='remove' /> */}
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
              {/* <div
                onClick={()=>handleRemove()}
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </div> */}
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
            {/* end::Hint */}
            {!data.avatar && hasError && (
              <div className='pt-5 fv-plugins-message-container'>
                <div data-field='name' data-validator='notEmpty' className='fv-help-block'>
                  Please upload an image
                </div>
              </div>
            )}
          </div>


          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 4 */}
    </>
  )
}

export { Step4 };

