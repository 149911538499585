import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { convertArrToSelect, getDateTime, copyToClipboard, downloadFile } from '../../../../../../jsFunctions';
import { ContentsListLoading } from '../components/loading/ContentsListLoading';
import { useListView } from '../core/ListViewProvider';
import { useQueryResponse } from '../core/QueryResponseProvider';
import { Session, initialSession } from '../core/_models';
import { updateSession as createSession, updateSession, uploadAndUpdate } from '../core/_requests';
import { navigateToModal } from '../../../../../../jsFunctions';

type Props = {
  isSessionLoading: boolean
  session: Session
}

const editSessionSchema = Yup.object().shape({

  // name: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Name is required'),
})


interface DropdownOptions {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'session', label: 'session', color: '#00B8D9', isFixed: true },
  { value: 'client', label: 'client', color: '#00B8D9', isFixed: true },
  { value: 'testing', label: 'testing', color: '#00B8D9', isFixed: true },
  { value: 'SAYCHEESE', label: 'SAYCHEESE', color: 'red', isDisabled: true },
];

const SessionEditModalForm: FC<Props> = ({ session, isSessionLoading }) => {
  const { setItemIdForUpdate, setSessionIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const [sessionForEdit] = useState<Session>({
    ...session,
    avatar: session.avatar || initialSession.avatar,
    info: session.info || initialSession.info,
    name: session.name || initialSession.name,
    tags: session.tags ? convertArrToSelect(session.tags) : dropdownOptions
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    // setItemIdForUpdate(undefined)
    setSessionIdForUpdate && setSessionIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const [avatar, setAvatar] = useState<any>(sessionForEdit.avatar);

  const [dimensions, setDimensions] = useState<string>("");


  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    // const { target } = event;
    // const img = new Image();

    const ImageName = event.target.value.split('\\')[2];
    const Image = event.target.value;
    console.log('Imageon Kevel: ', Image); // Image on Kevel
    console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
    console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel
    // console.log('event current Target files: ', event.target?.files?[0].path);

    if (event?.target?.files?.[0]!) {
      setAvatar({ display: URL.createObjectURL(event?.target?.files?.[0]), upload: event?.target?.files?.[0] });
    }
  };

  useEffect(() => {
    console.log("sessionForEdit ", sessionForEdit)

    const image = new Image();
    image.src = sessionForEdit.avatar;

    image.onload = () => {
      setDimensions(image.width + " by " + image.height);
    };

    image.onerror = () => {
      console.error('Error loading image');
    };
  }, [sessionForEdit.avatar, sessionForEdit.activations]);


  const formik = useFormik({
    initialValues: sessionForEdit,
    validationSchema: editSessionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateSession({ ...values })
          if (avatar.upload && values.id) {
            uploadAndUpdate(values.id, avatar.upload, values.id)
          }
        } else {
          await createSession({ ...values, avatar: avatar.upload })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_content_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_content_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_content_header'
          data-kt-scroll-wrappers='#kt_modal_add_content_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Session Number</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Session Number'
              value={sessionForEdit.sessionNumber}
              type='text'
              name='sessionNumber'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              disabled={true}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Creation Date</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Creation Date'
              {...formik.getFieldProps('dateCreated')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}



          {
            sessionForEdit.memoryMakers?.length ?
              <div className='fv-row mb-3'>
                {/* begin::Label */}
                <div className='card-header border-0 mb-5'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Memory Maker</h3>
                </div>
                {/* end::Label */}
                <a className='d-flex align-items-center mb-7'  href='#'  onClick={() => navigateToModal("user", sessionForEdit.memoryMakers[0].id)}>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5'>
                    <img src={sessionForEdit.memoryMakers[0].avatar ? sessionForEdit.memoryMakers[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a className='text-dark fw-bold text-hover-primary fs-6'>
                      {sessionForEdit.memoryMakers[0].label}
                    </a>
                    <span className='text-muted d-block fw-semibold'>{sessionForEdit.memoryMakers[0].data.email}</span>
                  </div>
                  {/* end::Text */}
                </a>
              </div>
              :
              null
          }

          {/* begin::Card Header */}
          <div className='card-header border-0 mb-5'>
            <h3 className='card-title fw-bold text-dark fs-6'>Contents</h3>
          </div>
          {/* end::Card Header */}

          {/* begin::Contents */}
          {sessionForEdit.parent?.length ? (
            sessionForEdit.parent.map((parent: any, index: number) => (
              <a key={index} className='fv-row mb-3' href='#'  
              onClick={() => {
                navigateToModal("content", parent.value)
              }}>
                {/* begin::Label */}
                {/* end::Label */}
                <div className='d-flex align-items-center mb-7 position-relative'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-100px me-5'>
                    <img
                      className='image-input-wrapper'
                      src={parent.avatar ? parent.avatar : blankImg}
                      alt='Image Alt Text'
                      style={{
                        width: '100%',
                        height: '150px',
                      }}
                    />

                    {/* Download Button */}
                    <a
                      // href={parent.avatar ? parent.avatar : blankImg}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        downloadFile(parent.avatar || blankImg, sessionForEdit.sessionNumber + "-" + parent.data.templateType);
                      }}
                      download={sessionForEdit.id + '.jpg'}
                      className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute bottom-0 end-0 m-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Download Image'
                    >
                      <i className='bi bi-download'></i>
                    </a>
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {parent.data.templateType}
                    </a>
                    <span className='text-muted d-block fw-semibold'>
                      {getDateTime(parent.data.dateCreated.seconds * 1000)}</span>
                  </div>
                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(parent.value);
                    }}
                  >
                    Copy
                  </button>
                </div>
              </a>
            ))
          ) : null}
          {/* end::Contents */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Last Modified</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Creation Date'
              {...formik.getFieldProps('updatedOn')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}



          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Consent</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Consent'
              value={JSON.stringify(sessionForEdit.consent)}
              type='text'
              name='consent'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',

              )}
              autoComplete='off'
              disabled={true}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {
            sessionForEdit.activations?.length ? (
              <div className='fv-row mb-3'>
                {/* begin::Label */}
                <div className='card-header border-0 mb-7'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Activation</h3>
                </div>
                {/* end::Label */}
                <a className='d-flex align-items-center mb-7' href='#' onClick={() => navigateToModal("event", sessionForEdit.activations[0].id)}>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5' >
                    <img src={sessionForEdit.activations[0].avatar ? sessionForEdit.activations[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {sessionForEdit.activations[0].label}
                    </a>
                    <span className='text-muted d-block fw-semibold fs-6'>
                      {getDateTime(sessionForEdit.activations[0].data.startDate.seconds * 1000)}
                    </span>
                    <span className='text-muted d-block fw-semibold mt-3'>
                      DOOH: {sessionForEdit.activations[0].data.isDooh ? sessionForEdit.activations[0].data.dooh : 'Disabled'}
                    </span>
                    <span className='text-muted d-block fw-semibold mt-3'>
                      SW: {sessionForEdit.activations[0].data.isMicrosite ? sessionForEdit.activations[0].data.microsite : 'Disabled'}
                    </span>
                  </div>

                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(sessionForEdit.activations[0].value);
                    }}
                  >
                    Copy
                  </button>
                </a>
              </div>
            ) : null
          }

          {
            sessionForEdit.collections?.length ? (
              <div className='fv-row mb-3' >
                {/* begin::Label */}
                <div className='card-header border-0 mb-5'>
                  <h3 className='card-title fw-bold text-dark fs-6'>Collections</h3>
                </div>
                {/* end::Label */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5' onClick={() => navigateToModal("campaign", sessionForEdit.collections[0].id)}>
                    <img src={sessionForEdit.collections[0].avatar ? sessionForEdit.collections[0].avatar : blankImg} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {sessionForEdit.collections[0].label}
                    </a>
                    <span className='text-muted d-block fw-semibold'>
                      {getDateTime(sessionForEdit.collections[0].data.startDate.seconds * 1000)}
                    </span>
                  </div>
                  {/* end::Text */}
                  {/* Copy Button */}
                  <button
                    className='btn btn-info btn-sm'
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      e.preventDefault(); // Prevent the form from being submitted
                      copyToClipboard(sessionForEdit.collections[0].value);
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
            ) : null
          }

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              QR Codes: <span className='fs-6 text-muted fw-light'>({sessionForEdit.qrCodes ? sessionForEdit.qrCodes?.length : 0} codes)</span>
            </label>
            <div>
              {sessionForEdit.qrCodes?.map((item, index) => (
                <div key={index} className='badge badge-light-info fw-bolder m-2 p-2'>
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              Mails: <span className='fs-6 text-muted fw-light'>({sessionForEdit.emails ? sessionForEdit.emails?.length : 0} mails)</span>
            </label>
            <div>
              {sessionForEdit.emails?.map((item, index) => (
                <div key={index} className='badge badge-light-info fw-bolder m-2 p-2'>
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              Prints: <span className='fs-6 text-muted fw-light'>({sessionForEdit.prints} prints)</span>
            </label>

          </div>


        </div>
        {/* end::Scroll */}



        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-contents-modal-action='cancel'
            disabled={formik.isSubmitting || isSessionLoading}
          >
            Discard
          </button>

          {/* <button
            type='submit'
            className='btn btn-primary'
            data-kt-contents-modal-action='submit'
            disabled={isSessionLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isSessionLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button> */}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isSessionLoading) && <ContentsListLoading />}
    </>
  )
}

export { SessionEditModalForm };

