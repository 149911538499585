import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { App } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'
import { StepProps } from '../../../../../../../_metronic/partials/modals/create-event-stepper/IAppModels'
import { addRemoveArr, objInArr } from '../../../../../../../jsFunctions'
type Props = {
  row: Row<App>
  data: StepProps["data"],
  updateData: StepProps["updateData"],
  hasError: StepProps["hasError"]
}

const CustomRow: FC<Props> = ({ row, data, updateData, hasError }) => {
  const { selected, onSelect } = useListView()

  // console.log("row original is ", row.original)

  return (
    <tr style={{ opacity: objInArr(data.parent, row.original.id) ? 1 : 0.38 }} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            style={{ cursor: 'pointer' }}
            {...cell.getCellProps()}
            onClick={() => {
              console.log("onClick CustomRow");
              updateData({
                //hunnain 
                parent: data.type === 'Collections' ? addRemoveArr(data.parent, {
                  id: row.original.id,
                  label: row.original.title,
                  avatar: row.original.avatar,
                  startDate: row.original.startDate,
                })
                  : [{
                    id: row.original.id,
                    label: row.original.title,
                    avatar: row.original.avatar,
                    startDate: row.original.startDate,
                  }]

              })
            }}
            // onClick={() => onSelect(row.id)}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export { CustomRow }
