const AppsListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: 'rgb(26, 116, 232)',
    fontWeight: '700',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 1rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{...styles, position: 'absolute', textAlign: 'center'}}> Loading </div>
}

export {AppsListLoading}
