/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { getDocs, getFirestore, setDoc, doc, collection, query as firebaseQuery, where, Timestamp, orderBy } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { Modal } from 'react-bootstrap'
import { defaultCreateAppData, ICreateAppData } from './IAppModels'
import { StepperComponent } from '../../../assets/ts/components'
import { KTSVG } from '../../../helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import { findFirstAndLastDates, simpleSubsequencesForObject, removeEmptyAttributes } from '../../../../jsFunctions'
import { StepProps } from './IAppModels'

type Props = {
  show: boolean
  type: 'Activations' | 'Collections' | 'Clients'
  handleClose: () => void
}
interface DynamicObject {
  [key: string]: any;
}
const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({ show, type, handleClose }: Props) => {
  // const [showModal, setShowModal] = useState(show)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [hasError, setHasError] = useState(false)


  useEffect(() => {
    updateData({ type: type })
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate }
    setData(updatedData)
  }

  const checkAppParent = (): boolean => {
    if (!data.parent[0].id || !data.parent[0].label) {
      return false
    }
    return true
  }

  const checkAvatar = (): boolean => {
    if (!data.avatar) {
      return false
    }
    return true
  }

  const checkAppBasic = (): boolean => {
    if (!data.name || !data.type || !data.startDate) {
      return false
    }
    return true
  }

  const checkAppDataBase = (): boolean => {
    if (!data.tags.length) {
      return false
    }

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppParent()) {
        setHasError(true)
        return
      }
      console.log("step1")
      if (data.type === 'Collections') {
        console.log("findFirstAndLastDates", findFirstAndLastDates(data.parent).startDate)
        updateData({ startDate: findFirstAndLastDates(data.parent).startDate, endDate: findFirstAndLastDates(data.parent).endDate })
      }
    }

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkAppDataBase()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 4) {
      if (!checkAvatar()) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }


  const createApp = () => {
    const db = getFirestore()
    const panelUser = doc(collection(db, `_${data.type.toLowerCase()}`))

    //   firebase.storage().ref().put(avatar.upload).then(function(snapshot) {
    //     console.log('Uploaded a blob or file!')
    //  })

    if (data?.avatar) {

      //hunnain avatar is not uploading
      console.log("avatar is ", data.avatar)

      const storage = getStorage()
      const storageRef = ref(storage, `/panel${data.type}/dp/` + panelUser.id)
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, data.avatar).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          updateApp(panelUser.id, downloadURL)
        })
      })
    }

    const obj: DynamicObject = {};
    if (data.type === 'Activations') {
      const event = removeEmptyAttributes(data);
      if (event.name) {
        obj['name'] = event.name?.toLowerCase();
      }
      if (event.projectNo) {
        obj['projectNo'] = event.projectNo?.toLowerCase();
      }

      if (event.parent[0]) {
        obj['parentTitle'] = event.parent[0].label?.toLowerCase();
      }

      //hunnain check if event.parent[0].label or event.parent[0] in if
      event.tags?.map((item: string, index: number) => {
        obj[item] = item;
      })
    }
    else {
      const campaign = removeEmptyAttributes(data);

      if (campaign.name) {
        obj['name'] = campaign.name?.toLowerCase();
      }

      if (campaign.projectNo) {
        obj['projectNo'] = campaign.projectNo?.toLowerCase();
      }

      if (campaign.parent[0]) {
        obj['parentTitle'] = campaign.parent[0].label?.toLowerCase();
      }

      campaign.tags?.map((item: string, index: number) => {
        obj[item] = item;
      })

    }

    const subsequencesArray = simpleSubsequencesForObject(obj);

    const objToPost = { ...data, id: panelUser.id, parent: data.parent.map((item) => item.id), dateCreated: Timestamp.now(), avatar: "", zearch: subsequencesArray.concat(panelUser.id.toLowerCase()) }
    
    return new Promise(resolve => setDoc(panelUser, {...objToPost, [data.type === 'Collections' ? 'events' : data.type === 'Activations' ? 'owner' : "other"]: objToPost.parent, updatedOn:  Timestamp.now(),})
      .then((response) => {
        console.log(response)
        resolve(true)
      }).catch(error => {
        console.log(error)
        resolve(undefined)
      }))

    // return new Promise(resolve =>
    //   getDocs(collection(db, 'users'))
    //     .then(querySnapshot => {
    //       const usersArr = Array()
    //       querySnapshot.forEach(doc => {
    //         // console.log(`${doc.id} => ${doc.data()}`)
    //         const user = doc.data()
    //         usersArr.push({
    //           id: doc.id,
    //           last_login: getDateTime(user.metadata.lastSignInTime),
    //           two_steps: true,
    //           joined_day: getDateTime(user.metadata.creationTime),
    //           online: true,
    //           initials: {
    //             label: "labels",
    //             state: "state",
    //           },
    //           name: user.email.substring(0, user.email.indexOf("@")),
    //           password: user.password,
    //           email: user.email,
    //           first_name: user.first_name ? user.first_name : "Guest",
    //           last_name: user.last_name ? user.last_name : "Guest",
    //           fullname: user.fullname ? user.fullname : "Guest",
    //           occupation: user.occupation ? user.occupation : "N/A",
    //           companyName: user.companyName ? user.companyName : "N/A",
    //           phone: user.companyName ? user.companyName : "N/A",
    //           // roles: user.role ? user.role : "0",
    //           // avatar: user.image ? user.image : "N/A",
    //           avatar: null,//`avatars/300-${usersArr.length + 1}.jpg`,
    //           role: 'Support',
    //           language: 'en',
    //           timeZone: user.timeZone ? user.timeZone : "N/A",
    //           website: user.website ? user.website : "N/A",
    //         })
    //       })
    //       resolve(undefined)
    //     }).catch(error => {
    //       resolve(error)
    //       console.error(error)
    //     }))
    // console.log(user)

    // return axios
    //   .put(USER_URL, user)
    //   .then((response: AxiosResponse<Response<User>>) => response.data)
    //   .then((response: Response<User>) => response.data)
  }

  const updateApp = (id: string, avatar: any): Promise<any | undefined> => {
    const db = getFirestore()
    if (id) {
      const panelUser = doc(db, `_${data.type.toLowerCase()}`, id)
      return new Promise(resolve => setDoc(panelUser, { avatar: avatar }, { merge: true })
        .then((response) => {
          console.log(response)
          handleClose()
          // setShowModal(false)
          resolve(true)
        }).catch(error => {
          console.log(error)
          resolve(undefined)
        }))
    }
    else {
      return new Promise(resolve =>
        resolve(undefined)
      )
    }

  }


  const submit = () => {
    createApp()
    // window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='modal-header'>
        <h2> {type === "Collections" ? "Create Campaign" : type === "Activations" ? "Create Activation" : "Assign User to Client"}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{data.type === 'Collections' ? "Activations" : data.type === "Activations" ? "Clients" : "Users"}</h3>

                    <div className='stepper-desc'>{data.type === 'Collections' ? "Choose Activations" : data.type === "Activations" ? "Choose Owner" : "Choose Users"}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}


              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{data.type.substring(0, data.type.length - 1)} Info</h3>

                    <div className='stepper-desc'>Add {data.type.substring(0, data.type.length - 1)} Details</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon br-4 w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Tags</h3>

                    <div className='stepper-desc'>Add {data.type.substring(0, data.type.length - 1)} Related Tags</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}


              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{data.type.substring(0, data.type.length - 1)} Logo</h3>

                    <div className='stepper-desc'>Upload {data.type.substring(0, data.type.length - 1)} Logo</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>

                    <div className='stepper-desc'>Review and Submit</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid px-lg-10'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <Step1 data={data} updateData={updateData} hasError={hasError} />
              <Step2 data={data} updateData={updateData} hasError={hasError} />
              <Step3 data={data} updateData={updateData} hasError={hasError} />
              <Step4 data={data} updateData={updateData} hasError={hasError} />
              <Step5 />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn rounded-pill btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='rounded-pill btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={submit}
                  >
                    Submit{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>

                  <button
                    type='button'
                    className='rounded-pill btn btn-lg btn-primary btn btn-hover-transparent-success'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CreateAppModal }
