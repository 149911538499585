import Select from 'react-select';

interface DropdownFilterProps {
  label: string;
  options: any[]; // Replace 'any[]' with the actual type of your options
  value: any;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  isOpen: string | null;
  setIsOpen: React.Dispatch<React.SetStateAction<string | null>>;
  isBackdropVisible: boolean;
}

export const commonSelectStyles = {
  option: (base: any, state: any) => ({
    ...base,
    // Your common styles for options
  }),
  multiValue: (styles: any,
    // { data:any }
  ) => ({
    ...styles,
    borderRadius: 10,
    backgroundColor: '#432cffb8',
    color: 'white',
    boxShadow: '#0303030f 0px 0px 20px 0px',
  }),
  multiValueLabel: (styles: any,
    // { data:any }
  ) => ({
    ...styles,
    fontWeight: 'bold',
    color: 'white',
  }),
  multiValueRemove: (styles: any,
    // { data:any }
  ) => ({
    ...styles,
    ':hover': {
      color: 'gray',
    },
  }),
  control: (base: any) => ({
    ...base,
    padding: '5px',
    border: 'none',
    borderRadius: 10,
    boxShadow: '#3d88a614 0px 0px 20px 0px',
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: 10,
    boxShadow: '#3d88a614 0px 0px 20px 0px',
  }),
  singleValue: (base: any) => ({ ...base, color: '#000', borderRadius: 10 }),
};

export const DropdownFilter: React.FC<DropdownFilterProps> = ({
  label,
  options,
  value,
  setValue,
  isOpen,
  setIsOpen,
  isBackdropVisible,
}) => {
  return (
    <div style={{ opacity: (isOpen === "Type" && label === "Status") ? 0.25 : 1 }}>
      <label className='form-label fs-6 fw-bold mt-5'>{label}:</label>
      <Select
        placeholder={`Select ${label} to Filter`}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onChange={(newValue) => {
          setValue(newValue?.value);
          setTimeout(() => { setIsOpen(null) }, 100);
        }}
        menuIsOpen={isOpen === label ? true : false}
        onMenuOpen={() => {
          setIsOpen(label);
        }}
        backspaceRemovesValue={false}
        isOptionSelected={(opt, allOpts) => opt.value === value}
        blurInputOnSelect={false}
        className='text-uppercase fs-8'
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
          },
        })}
        isMulti={false}
        styles={commonSelectStyles}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name="color"
        options={options}
        value={value ? options?.filter((item: any) => item.value === value) : null}
      />
    </div>
  );
};