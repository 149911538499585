
import { useState } from 'react';

import Select from 'react-select';


interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const colourOptions: readonly ColourOption[] = [
  { value: 'ocean', label: '1', color: '#00B8D9', isFixed: true },
  { value: '2', label: '2', color: '#00B8D9', isFixed: true },
  { value: '3', label: '3', color: '#00B8D9', isFixed: true },
  { value: '4', label: '4', color: 'green', isFixed: true },
  { value: '5', label: '5', color: 'white', isFixed: true },
  { value: '6', label: '6', color: 'black', isFixed: true },
  { value: '7', label: '7', color: 'red', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
];

export function Dropdown1() {
  const [value, setValue] = useState<any>([colourOptions[2], colourOptions[3]]);

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>
          <div onClick={(event) => {
            event.stopPropagation();
          }}>
            
            <Select
              value={value}
              hideSelectedOptions={false}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              backspaceRemovesValue={false}
              blurInputOnSelect={false}
              // closeMenuOnSelect={false}
              className=''
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                },
              })}
              isMulti={true}
              styles={{
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: 'white',
                    boxShadow: '#0303030f 0px 0px 20px 0px'
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  ':hover': {
                    color: 'gray',
                  },
                }),
                control: (base) => ({
                  ...base,
                  // backgroundColor: var(--kt-input-solid-bg),
                  zIndex: 9999,
                  padding: "5px",
                  border: "none",
                  borderRadius: 10,
                  boxShadow: '#3d88a614 0px 0px 20px 0px'
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 19999,
                  backgroundColor: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: 10,
                  boxShadow: '#3d88a614 0px 0px 20px 0px'
                }),
                singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
              }}
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="color"
              options={colourOptions}
            />
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Member Type:</label>
          <div className='d-flex'>
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='1' />
              <span className='form-check-label'>Author</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
              <span className='form-check-label'>Customer</span>
            </label>
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Notifications:</label>
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={true}
            />
            <label className='form-check-label'>Enabled</label>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>
          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
