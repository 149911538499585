import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { ContentsListFilter } from './ContentsListFilter'; // Import ContentsListFilter component
import { useQueryResponse } from '../../core/QueryResponseProvider';

type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
  className?: string;
  title?: string;
};

interface Filter {
  type: string; // replace with the actual types
  status: string
}

const ContentsListToolbar = ({
  toggleBtnClass = 'btn-color-gray-700 btn-active-color-primary w-40px h-40px',
  toggleBtnIconClass = 'svg-icon-2',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const { updateState, state } = useQueryRequest();
  const { isLoading } = useQueryResponse();
  const [filterApplied, setFilterApplied] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization();

    if ((state.filter as { status?: string })?.status) {
      setFilterApplied(true)
    }
    else {
      setFilterApplied(false)
    }
  }, [state.filter]);

  const updateView = (filter: 'gallery' | 'table') => {
    updateState({
      view: filter,
    });
  };

  return (
    <div className='d-flex justify-content-end'>

      <div data-kt-template-table-toolbar='base'>
        {/* Button 1 */}
        <a
          href='#'
          className={clsx('btn btn-icon position-relative', toggleBtnClass)}
          data-kt-menu-trigger={menuTrigger}
          data-kt-menu-attach='parent'
          data-kt-menu-placement={menuPlacement}
        >
          {/* Conditionally render badge just above and to the right of the icon */}
          {filterApplied && (
            <span className="badge bg-primary position-absolute top-0 start-100 translate-middle p-1.5"
              style={{ fontSize: '0.8rem', cursor: 'pointer', marginLeft: '-10px' }}
            >
              1
            </span>
          )}
          <KTSVG
            path='/media/icons/duotune/general/gen031.svg'
            className={clsx('theme-dark-hide', toggleBtnIconClass)}
          />
        </a>

        {/* ContentsListFilter component */}
        <ContentsListFilter />

      </div>

      <div data-kt-template-table-toolbar='base'>
        <a
          href='#'
          className={clsx('btn btn-icon  ', toggleBtnClass)}
          data-kt-menu-trigger={menuTrigger}  // Use the same menu trigger as the first button
          data-kt-menu-attach='parent'
          data-kt-menu-placement={menuPlacement}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={clsx('theme-dark-hide', toggleBtnIconClass)}
          />
        </a>
        <>
          {/* begin::View Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
            data-kt-menu='true'
          >
            <div className='menu-item px-3 my-0'>
              <a
                href='#'
                className={clsx('menu-link px-3 py-2', { active: state.view === 'table' })}
                onClick={() => {
                  updateView('table');
                }}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTSVG path='/media/icons/duotune/layouts/lay007.svg' className='svg-icon-3' />
                </span>
                <span className='menu-title'>Table</span>
              </a>
            </div>

            <div className='menu-item px-3 my-0'>
              <a
                href='#'
                className={clsx('menu-link px-3 py-2', { active: state.view === 'gallery' })}
                onClick={() => {
                  updateView('gallery');
                }}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTSVG path='/media/icons/duotune/layouts/lay002.svg' className='svg-icon-3' />
                </span>
                <span className='menu-title'>Gallery</span>
              </a>
            </div>
          </div>
          {/* end::View Menu */}
        </>
      </div>
    </div>
  );
};

export { ContentsListToolbar };
