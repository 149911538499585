/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { Session } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  session?: string
  sessionNumber?: string
}

const SessionCell: FC<Props> = ({ sessionNumber, session }) => {
  const { setSessionIdForUpdate, setFlagForDelete } = useListView()

  return (
    <a href='#' className='d-flex align-items-center'
    onClick={() => {
      setSessionIdForUpdate && setSessionIdForUpdate(session)
    }}
    >
      {/* begin:: Avatar */}
      <div  className='symbol symbol-oval symbol-50px overflow-hidden me-3'>
        <div className='d-flex flex-column'>
          <a onClick={() =>  setSessionIdForUpdate && setSessionIdForUpdate(session)}
          className='text-gray-800 text-hover-primary mb-1'>
            {sessionNumber}
          </a>
          {/* <span>{session}</span> */}
        </div>
      </div>
    </a>
  )
}

export { SessionCell }
