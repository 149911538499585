/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { navigateToModal } from '../../../../../../../jsFunctions'
// import { Client } from '../../../../parent-management/clients-list/core/_models'

type Props = {
  parent: {
    avatar?: string
    label?: string
  },
  navigation: {
    id?: string
    path?: string
  }
}

const ParentInfoCell: FC<Props> = ({ parent, navigation }) => (
  <div className='d-flex align-items-center' onClick={() => navigateToModal(navigation.path, navigation.id)}>
    {/* begin:: Avatar */}
    <div className='symbol symbol-oval symbol-30px overflow-hidden me-3'>
      <a href='#'>
        {parent?.avatar ? (
          <div className='symbol-label'>
            <img src={parent?.avatar} alt={parent?.label} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${parent?.label}`,
              `text-${parent?.label}`
            )}
          >
            {parent?.label?.[0]}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <div className='text-gray-500 text-hover-primary fw-normal'>
        {parent?.label}
      </div>
    </div>
  </div>
)

export { ParentInfoCell }

