import { Column } from 'react-table'
import { Client } from '../../core/_models'
import { ClientActionsCell } from './ClientActionsCell'
import { ClientCustomHeader } from './ClientCustomHeader'
import { ClientEnabledDisabled } from './ClientEnabledDisabled'
import { ClientInfoCell } from './ClientInfoCell'
import { ClientSelectionCell } from './ClientSelectionCell'
import { ClientSelectionHeader } from './ClientSelectionHeader'
import { ClientTwoStepsCell } from './ClientTwoStepsCell'


const clientsColumns: ReadonlyArray<Column<Client>> = [
  {
    Header: (props) => <ClientSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <ClientSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='Avatar' className='min-w-125px' />,
    id: 'composite_avatar',
    Cell: ({ ...props }) => <ClientInfoCell client={props.data[props.row.index]} avatar={true} />,
  },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <ClientInfoCell client={props.data[props.row.index]} avatar={false} />,
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Activated' className='min-w-125px' />
    ),
    id: 'deactivate',
    Cell: ({ ...props }) => <ClientEnabledDisabled value={props.data[props.row.index].deactivate === true ? "false" : "true"} text={props.data[props.row.index].deactivate === true ? "No" : "Yes"} />,
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Users' className='min-w-125px' />
    ),
    id: 'composite_users',
    accessor: (row) => {
      // Here, you can manually calculate or access the projectNo from the row data
      // For example, if projectNo is a property of the row, you can do:
      return row.users?.length ? row.users.length : "0";

      // Alternatively, you can return a manually inputted value if needed
      // return 'manual-input-value';
    },
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Activations' className='min-w-125px' />
    ),
    id: 'composite_activations',
    accessor: (row) => {
      // Here, you can manually calculate or access the projectNo from the row data
      // For example, if projectNo is a property of the row, you can do:
      return row.activations?.length ? row.activations.length : "0";

      // Alternatively, you can return a manually inputted value if needed
      // return 'manual-input-value';
    },
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Tags' className='min-w-125px' />
    ),
    id: 'composite_tags',
    Cell: ({ ...props }) => <ClientTwoStepsCell tags={props.data[props.row.index].tags} />,
  },
  // {
  //   Header: (props) => (
  //     <ClientCustomHeader tableProps={props} title='Note' className='min-w-125px' />
  //   ),
  //   id: 'composite_info',
  //   accessor: 'info',
  // },
  // // {
  // //   Header: (props) => <ClientCustomHeader tableProps={props} title='Parent' className='min-w-125px' />,
  // //   accessor: 'parent',
  // // },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ClientActionsCell id={props.data[props.row.index].id} />,
  },
]

export { clientsColumns }

