import { collection, deleteDoc, doc, query as firebaseQuery, getDoc, getDocs, getFirestore, limit, orderBy, setDoc, startAfter, updateDoc, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { ID } from '../../../../../../_metronic/helpers'
import { convertSelectToArr, getBoolean, getDateTime, getDropdownOptionsFromFirebase, removeEmptyAttributes, simpleSubsequencesForObject, checkIfIndexRequired } from '../../../../../../jsFunctions'
import { generatePaginationLinks, getValueByNumber } from '../../../../../../tsFunctions'
import { Event, EventsQueryResponse } from './_models'

const collection_name = "_activations";
const API_URL = process.env.REACT_APP_THEME_API_URL
const EVENT_URL = `${API_URL}/user`
const GET_EVENTS_URL = `${API_URL}/users/query`
interface DynamicObject {
  [key: string]: any;
}
const getEvents = async (query: string): Promise<EventsQueryResponse> => {
  const db = getFirestore()
  let params = new URLSearchParams(query);

  let sort = params.get('sort')
  let lastVisible = params.get('lastVisible')
  let page: number = Number(params.get('page'))
  let from: number = Number(params.get('from'))
  let search = params.get('search')?.toLowerCase()
  let order = params.get('order')
  let history = params.get('history')
  interface Filter {
    type: 'isMicrosite' | 'isDooh' | 'isAnalytics'; // replace with the actual types
    status: string
    // other properties...
  }
  let filter: Filter = Object({ type: params.get('filter_type'), status: params.get('filter_status') })
  const promises: Promise<void>[] = []; // Array to store promises

  const parseHistoryObj = (historyString: string) => {
    if (!historyString) return [];
    return JSON.parse(historyString);
  };

  const parsedHistory = parseHistoryObj(history as string);

  let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);

  if (!lastVisible && startParam.value) {
    lastVisible = startParam.value
  }

  console.log("query ", query)
  console.log(": ", lastVisible, "\t", startParam.value);
  console.log(filter)

  let q = firebaseQuery(collection(db, collection_name), limit(6));

  if (lastVisible && page > 1) {
    const counter = page - from;
    const pageLimit = 5;
    let entries = pageLimit * counter + 1;
    if (from > page) {
      let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);
      if (startParam.value) {
        lastVisible = startParam.value ? startParam.value : lastVisible
      }
      if (page === startParam.key) {
        entries = pageLimit + 1;
      }
      else {
        entries = pageLimit * (page - startParam.key) + 1;
      }
    }

    return getDoc(doc(db, collection_name, lastVisible)).then((docSnap) => {
      if (sort && (order === "desc" || order === "asc") && search) {
        q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), startAfter(docSnap), limit(entries))

        if (filter.type) {
          q = firebaseQuery(collection(db, collection_name),
            orderBy(sort, order), startAfter(docSnap),
            limit(entries),
            where("zearch", "array-contains", search),
            where(filter.type, "array-contains", filter.status),
          )
        }
      }
      else if (sort && (order === "desc" || order === "asc")) {
        q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), startAfter(docSnap), limit(entries))

        if (filter.type) {
          q = firebaseQuery(collection(db, collection_name),
            orderBy(sort, order),
            startAfter(docSnap),
            limit(entries),
            where(filter.type, "array-contains", filter.status),
            // where("zearch", "array-contains", filter.status == true ? filter.type : filter.type + "Disabled")
          )
        }
      }
      else if (search) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), startAfter(docSnap), limit(entries))

        if (filter.type) {
          q = firebaseQuery(collection(db, collection_name),
            orderBy("dateCreated", "desc"),
            startAfter(docSnap),
            limit(entries),
            where(filter.type, "array-contains", filter.status),
            where("zearch", "array-contains", search),
            // where("zearch", "array-contains-any", filter.status == true ? [filter.type, search] : [filter.type + "Disabled", search])
          )
        }
      }
      else {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(entries), startAfter(docSnap))
        if (filter.type) {
          q = firebaseQuery(collection(db, collection_name),
            orderBy("dateCreated", "desc"),
            limit(entries),
            startAfter(docSnap),
            where(filter.type, "array-contains", filter.status)
            // where("zearch", "array-contains", filter.status == true ? filter.type : filter.type + "Disabled")

          )
        }
      }

      return new Promise(resolve =>
        getDocs(q)
          .then(querySnapshot => {
            const eventsArr = Array()

            let docs = querySnapshot.docs.slice();
            let landingPage = Math.ceil((querySnapshot.size - 1) / pageLimit);
            if (querySnapshot.size === 1) {
              landingPage = 1;
            }
            if (page > from) {
              page = from + landingPage;
            }
            //remove entries for pages that are not required
            if (landingPage > 1) {
              const toSplice = ((landingPage - 1) * pageLimit);
              docs.splice(0, toSplice);
            }
            //remove one extra entry if page is not last page
            if (docs.length > pageLimit) {
              docs.splice(-1);
              lastVisible = docs[docs.length - 1].id;
            }
            else {
              lastVisible = null;
            }

            docs.forEach(document => {
              const user = document.data()
              if (!user.id) {
                alert("No user id found for " + document.id)
              }
              eventsArr.push({
                id: document.id,
                dateCreated: getDateTime(user.dateCreated?.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
                startDate: getDateTime(user.startDate?.seconds * 1000),
                endDate: getDateTime(user.endDate?.seconds * 1000),
                memoryMakers: user.memoryMakers,
                templates: user.templates,
                tags: user.tags,
                info: user.info,
                isAnalytics: getBoolean(user.isAnalytics, true),
                embedCode: user.embedCode ? user.embedCode : "",
                projectNo: user.projectNo,
                avatar: user.avatar ? user.avatar : "",
                name: user.name,

                isForm: getBoolean(user.isForm, true),
                form: user.form,
                qrCards: getBoolean(user.qrCards, true),
                isMail: getBoolean(user.isMail, true),
                sendgrid: user.sendgrid,
                wixMail: user.wixMail,
                mailParagraph: user.mailParagraph ? user.mailParagraph : "",
                isMicrosite: getBoolean(user.isMicrosite, true),
                microsite: user.microsite,
                micrositeBeta: user.micrositeBeta,
                micrositeDate: user.micrositeDate?.seconds ? getDateTime(user.micrositeDate.seconds * 1000) : null,
                micrositeName: user.micrositeName,
                isDooh: getBoolean(user.isDooh, true),
                dooh: user.dooh,
                quitclaim: user.quitclaim,
                mailParagraphDooh: user.mailParagraphDooh ? user.mailParagraphDooh : "",
                sendgridDooh: user.sendgridDooh,
              })

              let parentPromise = getDropdownOptionsFromFirebase("id", "_clients", user.clients, user.owner[0], null, null, document.id).then(({ parent, owner, associatedCollections }) => {
                const index_ = eventsArr.findIndex(obj => obj.id === document.id);
                if (index_ !== -1) {
                  eventsArr[index_] = { ...eventsArr[index_], parent: owner, clients: parent, associatedCollections };
                }
              })

              promises.push(parentPromise);
            })

            return Promise.all(promises).then(() => {

              page = eventsArr.length ? page : 1

              resolve({
                data: eventsArr,
                payload: {
                  message: "N/A",
                  errors: {},
                  "pagination": {
                    page: page,
                    items_per_page: 10,
                    lastVisible: lastVisible,
                    "first_page_url": "/?page=1",
                    "from": 1,
                    "last_page": page,
                    "next_page_url": "\\/?page=" + (page + 1),
                    "prev_page_url": null,
                    "to": querySnapshot.docs.length + 1,
                    // "total": 21,
                    "history": { ...parsedHistory, [page]: eventsArr.length === pageLimit ? eventsArr[pageLimit - 1].id : null },
                    "links": generatePaginationLinks(page, lastVisible)
                  }
                }
              })
            })
          }).catch(error => {
            console.error(error)
            checkIfIndexRequired(error.message)
            resolve(error)
          }))
    })
  }
  else {

    const pageLimit = 5 * page;

    if (sort && (order === "desc" || order === "asc") && search) {
      q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), limit(pageLimit + 1))

      if (filter.type) {
        q = firebaseQuery(collection(db, collection_name),
          orderBy(sort, order),
          limit(pageLimit + 1),
          where(filter.type, "array-contains", filter.status),
          where("zearch", "array-contains", search),
        )
      }
    }
    else if (sort && (order === "desc" || order === "asc")) {
      q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), limit(pageLimit + 1))

      if (filter.type) {
        q = firebaseQuery(collection(db, collection_name),
          where(filter.type, "array-contains", filter.status),
          orderBy(sort, order),
          limit(pageLimit + 1))
      }
    }
    else if (search) {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), limit(pageLimit + 1))


      if (filter.type) {
        q = firebaseQuery(collection(db, collection_name),
          orderBy("dateCreated", "desc"),
          limit(pageLimit + 1),
          where(filter.type, "array-contains", filter.status),
          where("zearch", "array-contains", search),
        )
      }
    }
    else {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(pageLimit + 1))

      if (filter.type) {
        q = firebaseQuery(collection(db, collection_name),
          where(filter.type, "array-contains", filter.status),
          orderBy("dateCreated", "desc"),
          limit(pageLimit + 1))
      }
    }

    // if (search) {
    //   q = firebaseQuery(q, where("zearch", "array-contains", search));
    // }

    // if (sort && (order === "desc" || order === "asc")) {
    //   q = firebaseQuery(q, orderBy(sort, order));
    // }

    // if (filter && filter.type) {
    //   q = firebaseQuery(q, where(filter.type, "array-contains", filter.status));
    // }

    // q = firebaseQuery(q, orderBy("dateCreated", "desc"), limit(pageLimit + 1));

    // console.log(q)


    return new Promise(resolve =>
      getDocs(q)
        .then(querySnapshot => {
          const eventsArr = Array()
          let index = 0;

          querySnapshot.forEach(document => {
            if (pageLimit > 5 && index < pageLimit - 5) {

            }
            else {
              const user = document.data()
              console.log(user.id)

              if (!user.id) {
                alert("No user id found for " + document.id)
              }
              // updateDoc(doc(db, collection_name, document.id), {
              //   startDate: new Date(),
              //   endDate: new Date()
              // });

              // console.log(user.startDate, " ", user.endDate, " ",user.dateCreated)
              // let parentPromise = getDropdownOptionsFromFirebase("id", "_clients", user.clients).then(({ parent }) => {
              //   eventsArr.push({
              //     id: document.id,
              //     dateCreated: getDateTime(user.dateCreated?.seconds * 1000),
              //     startDate: getDateTime(user.startDate?.seconds * 1000),
              //     endDate: getDateTime(user.endDate?.seconds * 1000),
              //     memoryMakers: user.memoryMakers,
              //     templates: user.templates,
              //     tags: user.tags,
              //     parent: parent,
              //     info: user.info,
              //     projectNo: user.projectNo,
              //     avatar: user.avatar ? user.avatar : "",
              //     name: user.name
              //   })
              // })

              eventsArr.push({
                id: document.id,
                dateCreated: getDateTime(user.dateCreated?.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
                startDate: getDateTime(user.startDate?.seconds * 1000),
                endDate: getDateTime(user.endDate?.seconds * 1000),
                memoryMakers: user.memoryMakers,
                templates: user.templates,
                tags: user.tags,
                info: user.info,
                isAnalytics: getBoolean(user.isAnalytics, true),
                embedCode: user.embedCode ? user.embedCode : "",
                projectNo: user.projectNo,
                avatar: user.avatar ? user.avatar : "",
                name: user.name,
                isForm: getBoolean(user.isForm, true),
                form: user.form,
                qrCards: getBoolean(user.qrCards, true),
                isMail: getBoolean(user.isMail, true),
                sendgrid: user.sendgrid,
                wixMail: user.wixMail,
                mailParagraph: user.mailParagraph ? user.mailParagraph : "",
                isMicrosite: getBoolean(user.isMicrosite, true),
                microsite: user.microsite,
                micrositeBeta: user.micrositeBeta,
                micrositeDate: user.micrositeDate?.seconds ? getDateTime(user.micrositeDate.seconds * 1000) : null,
                micrositeName: user.micrositeName,
                isDooh: getBoolean(user.isDooh, true),
                dooh: user.dooh,
                quitclaim: user.quitclaim,
                mailParagraphDooh: user.mailParagraphDooh ? user.mailParagraphDooh : "",
                sendgridDooh: user.sendgridDooh,
              })

              let parentPromise = getDropdownOptionsFromFirebase("id", "_clients", user.clients, user.owner[0], null, null, document.id).then(({ parent, owner, associatedCollections }) => {
                const index_ = eventsArr.findIndex(obj => obj.id === document.id);

                if (index_ !== -1) {
                  eventsArr[index_] = { ...eventsArr[index_], parent: owner, clients: parent, associatedCollections };
                }
              })

              promises.push(parentPromise);
            }
            index++
          })
          return Promise.all(promises).then(() => {

            page = eventsArr.length ? page : 1

            lastVisible = eventsArr.length > 5 ? eventsArr[eventsArr.length - 2].id : null

            console.log(eventsArr)

            resolve({
              data: eventsArr.length > 5 ? eventsArr.slice(0, eventsArr.length - 1) : eventsArr,
              payload: {
                message: "N/A",
                errors: {},
                "pagination": {
                  page: page,
                  items_per_page: 10,
                  lastVisible: lastVisible,
                  "first_page_url": "/?page=1",
                  "from": 1,
                  // "last_page": 3,
                  "next_page_url": "\\/?page=" + (page + 1),
                  "prev_page_url": "\\/?page=" + (page - 1),
                  "to": querySnapshot.docs.length + 1,
                  // "total": 21,
                  "history": { ...parsedHistory, [page]: eventsArr.length > pageLimit ? eventsArr[pageLimit - 1].id : null },
                  "links": generatePaginationLinks(page, lastVisible)
                }
              }
            })
          })
        }).catch(error => {
          console.error(error)
          checkIfIndexRequired(error.message)
          resolve(error)
        }))
  }
}


interface dropdownOptions {
  readonly value: string;
  readonly avatar?: string;
  readonly label: string;
  readonly color: string;
  readonly id: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly tags?: Array<string>
}


const getTemplates = (): Promise<dropdownOptions[]> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, "_templates"), orderBy('dateCreated', 'desc'))

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {

        const temlpatesArr: dropdownOptions[] = [];

        querySnapshot.forEach((doc) => {
          const template = doc.data()
          temlpatesArr.push({
            value: doc.id,
            avatar: template.image,
            id: doc.id,
            label: template.name,
            // label: template.dimensions?.width + " by " + template.dimensions?.height + " - " + doc.id,
            color: '#000',
            tags: template.tags ? template.tags : []
            // isFixed: true,
            // isDisabled: false,
          })
        })

        resolve(temlpatesArr)

      }).catch(error => {
        console.error(error)
        // resolve(dropdownOptions)
      }))
}

const getClients = (): Promise<dropdownOptions[]> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, "_clients"))

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {

        const clientsArr: dropdownOptions[] = [];

        querySnapshot.forEach((doc) => {
          const client = doc.data()
          clientsArr.push({
            value: client.id,
            id: client.id,
            label: client.name,
            color: '#000',
            isFixed: true,
            isDisabled: false,
            tags: []
          })
        })

        resolve(clientsArr)

      }).catch(error => {
        console.error(error)
      }))
}

const getMemoryMakers = (uid: boolean): Promise<dropdownOptions[]> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, "_users"), where("role", "==", "Memory Maker"))

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {

        const memoryMakersArr: dropdownOptions[] = [];

        querySnapshot.forEach((doc) => {
          const memoryMaker = doc.data()
          memoryMakersArr.push({
            value: uid ? memoryMaker.uid : memoryMaker.id,
            id: uid ? memoryMaker.uid : memoryMaker.id,
            label: memoryMaker.name,
            // label: memoryMaker.email,
            color: '#000',
            isFixed: true,
            isDisabled: false,
          })
        })

        resolve(memoryMakersArr)

      }).catch(error => {
        console.error(error)
        // resolve(dropdownOptions)
      }))
}

const getEventById = (id: ID): Promise<Event | undefined> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, collection_name))
  q = firebaseQuery(collection(db, collection_name), where("id", "==", id))

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {
        const usersArr = Array()
        querySnapshot.forEach(doc => {
          const user = doc.data()
          getDropdownOptionsFromFirebase("id", "_clients", user.clients, user.owner[0], user.memoryMakers, user.templates).then((sharedClients) => {
            console.log(user)
            console.log(sharedClients)
            usersArr.push({
              id: doc.id,
              dateCreated: getDateTime(user.dateCreated.seconds * 1000),
              updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
              startDate: new Date(user.startDate.seconds * 1000),
              endDate: new Date(user.endDate.seconds * 1000),
              tags: user.tags,
              memoryMakers: sharedClients.memoryMakers,
              exportFolder: user.exportFolder,
              templates: sharedClients.templates,
              // parent: user.owner,
              parent: sharedClients.owner,
              clients: sharedClients.parent,
              info: user.info,
              projectNo: user.projectNo,
              avatar: user.avatar ? user.avatar : "",
              name: user.name,
              isForm: getBoolean(user.isForm, false),
              isIntro: getBoolean(user.isIntro, false),
              intro: user.intro,
              form: user.form,
              qrCards: getBoolean(user.qrCards, false),
              isMail: getBoolean(user.isMail, false),
              sendgrid: user.sendgrid,
              wixMail: user.wixMail,
              mailParagraph: user.mailParagraph ? user.mailParagraph : "",
              isMicrosite: getBoolean(user.isMicrosite, false),
              microsite: user.microsite,
              micrositeBeta: user.micrositeBeta,
              micrositeDate: user.micrositeDate?.seconds ? new Date(user.micrositeDate.seconds * 1000) : null,
              micrositeName: user.micrositeName,
              isDooh: getBoolean(user.isDooh, false),
              dooh: user.dooh,
              quitclaim: user.quitclaim,
              mailParagraphDooh: user.mailParagraphDooh ? user.mailParagraphDooh : "",
              sendgridDooh: user.sendgridDooh,
              isAnalytics: user.isAnalytics,
              embedCode: user.embedCode ? user.embedCode : "",
            })
            resolve(usersArr[0])
          })
        })

      }).catch(error => {
        console.error(error)
        resolve(error)
      }))
}

const uploadAndUpdate = (id: string, avatar: any, name: string) => {
  const db = getFirestore()
  const storage = getStorage()
  const storageRef = ref(storage, '/_activations/dp/' + name)
  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, avatar).then((snapshot) => {
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      updateDoc(doc(db, collection_name, id), {
        avatar: downloadURL,
        updatedOn: new Date()
      });

      // updateUser({ id: panelUser.id, avatar: downloadURL })
    })
  })
}

const updateEvent = (event: Event): Promise<Event | undefined> => {
  const db = getFirestore()

  event.dateCreated = undefined



  const fEvent = removeEmptyAttributes(event)

  if (fEvent.id) {
    const _activations = doc(db, collection_name, fEvent.id)

    const obj: DynamicObject = {};

    if (fEvent.name) {
      obj['name'] = fEvent.name?.toLowerCase();
    }

    if (fEvent.projectNo) {
      obj['projectNo'] = fEvent.projectNo?.toLowerCase();
    }

    obj['projectNo'] = fEvent.projectNo?.toLowerCase();

    // event.memoryMakers?.map((item, index) => {
    //   obj[item.label] = item.label.toLowerCase();
    // })

    //hunnain handle parents named here later
    // if (fEvent.parent.parentTitle?.label) {
    //   obj['parentTitle'] = fEvent.parent[0].parentTitle?.label?.toLowerCase();
    // }

    const tags = convertSelectToArr(fEvent.tags);

    if (tags?.length) {
      tags.map((item: string, index: number) => {
        obj[item] = item;
      })
    }

    const subsequencesArray = simpleSubsequencesForObject(obj);

    // if (fEvent.isMicrosite) {
    //   subsequencesArray.push('isMicrosite')
    // }
    // if (fEvent.isDooh) {
    //   subsequencesArray.push('isDooh')
    // }
    // if (fEvent.isAnalytics) {
    //   subsequencesArray.push('isAnalytics')
    // }

    console.log("fEvent ", fEvent)

    console.log("removeEmptyAttributes(fEvent)", removeEmptyAttributes(fEvent))

    return new Promise(resolve => setDoc(_activations, {
      ...removeEmptyAttributes(fEvent),
      embedCode: event.isAnalytics ? event.embedCode : "",
      clients: event.clients.map((item: any) => item.id),
      memoryMakers: fEvent.memoryMakers ? convertSelectToArr(fEvent.memoryMakers, true) : [],
      templates: fEvent.templates ? convertSelectToArr(fEvent.templates, true) : [],
      tags: convertSelectToArr(fEvent.tags), zearch: subsequencesArray.concat(fEvent.id.toLowerCase()),
      updatedOn: new Date(),
      microsite: event.isMicrosite ? event.microsite : "",
      micrositeBeta: event.isMicrosite ? event.micrositeBeta : "",
      micrositeDate: (event.isMicrosite && event.micrositeDate) ? new Date(event.micrositeDate) : null,
      micrositeName: event.isMicrosite ? event.micrositeName : ""
    }, { merge: true })
      .then((response) => {
        console.log(response)
        resolve(event)
      }).catch(error => {
        console.log(error)
        resolve(undefined)
      }))
  }
  else {
    return new Promise(resolve =>
      resolve(undefined)
    )
  }
}

const createEvent = (event: Event): Promise<Event | undefined> => {

  return new Promise(resolve => {
    alert("Create Event is Non Functional")
  }
  );
  // const db = getFirestore();

  // console.log("creating event");

  // alert(event.id)

  // if (event.id) {
  //   const _activations = doc(db, collection_name, event.id);

  //   const obj: DynamicObject = {};

  //   if (event.name) {
  //     obj['name'] = event.name?.toLowerCase();
  //   }

  //   if (event.projectNo) {
  //     obj['projectNo'] = event.projectNo?.toLowerCase();
  //   }

  //   event.memoryMakers?.map((item, index) => {
  //     obj[item.label] = item.label.toLowerCase();
  //   });

  //   const tags = convertSelectToArr(event.tags);

  //   if (tags?.length) {
  //     const tags = convertSelectToArr(event.tags);
  //     tags.map((item: string, index: number) => {
  //       obj[item] = item;
  //     });
  //   }

  //   const subsequencesArray = simpleSubsequencesForObject(obj);

  //   return new Promise(resolve =>
  //     setDoc(_activations, { avatar: event.avatar, name: event.name, startDate: event.startDate, endDate: event.endDate, tags: convertSelectToArr(event.tags), info: event.info, zearch: subsequencesArray.concat(event.id ? event.id.toLowerCase() : "") }, { merge: true })
  //       .then((response) => {
  //         console.log(response);
  //         resolve(event);
  //       })
  //       .catch(error => {
  //         console.log(error);
  //         resolve(undefined);
  //       })
  //   );
  // } else {
  //   return new Promise(resolve =>
  //     resolve(undefined)
  //   );
  // }
};


type Info = {
  collections: number;
  contents: number;
};

const prepareForDeletion = (id: ID): Promise<Info> => {
  const db = getFirestore();

  const q = firebaseQuery(collection(db, "_collections"), where('events', 'array-contains', id));
  const q2 = firebaseQuery(collection(db, "_contents"), where('event', '==', id));

  const obj: Info = {
    collections: 0,
    contents: 0,
  };

  // Use Promise.all to wait for both asynchronous calls to complete
  return Promise.all([getDocs(q), getDocs(q2)])
    .then(([collectionsSnapshot, contentsSnapshot]) => {
      obj.collections = collectionsSnapshot.size;
      obj.contents = contentsSnapshot.size;

      return obj;
    })
    .catch((error) => {
      console.error(error);
      throw error; // Rethrow the error to propagate it further if needed
    });
};


const archiveEvent = (eventId: ID): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  if (eventId) {
    const userDocRef = doc(db, collection_name, eventId);

    // Retrieve the document data
    return getDoc(userDocRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.data();

          // Set the document data in the new collection
          const archiveDocRef = doc(db, collectionName, eventId);
          return setDoc(archiveDocRef, userData)
            .then(() => {
              // Delete the document from the original collection
              return deleteDoc(userDocRef);
            })
            .catch((error) => {
              console.error("Error archiving user:", error);
              throw error;
            });
        } else {
          throw new Error("User document not found.");
        }
      })
      .catch((error) => {
        console.error("Error retrieving user document:", error);
        throw error;
      });
  } else {
    return Promise.reject(new Error("Invalid user ID"));
  }
}


const archiveSelectedEvents = (selectedIds: Array<ID>): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  // Use Promise.all to run the promises concurrently
  const archivePromises = selectedIds.map(documentId => {
    if (documentId) {
      const userDocRef = doc(db, collection_name, documentId);

      // Retrieve the document data
      return getDoc(userDocRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();

            // Set the document data in the new collection
            const archiveDocRef = doc(db, collectionName, documentId);
            return setDoc(archiveDocRef, userData)
              .then(() => {
                // Delete the document from the original collection
                return deleteDoc(userDocRef);
              })
              .catch((error) => {
                console.error("Error archiving template:", error);
                throw error;
              });
          } else {
            throw new Error(`document with ID ${documentId} not found.`);
          }
        })
        .catch((error) => {
          console.error(`Error retrieving document with ID ${documentId}:`, error);
          throw error;
        });
    } else {
      return Promise.reject(new Error("Invalid ID"));
    }
  });

  // Return a promise that resolves when all archive operations are complete
  return Promise.all(archivePromises).then(() => { });
};

export { getEvents, archiveEvent, archiveSelectedEvents, getEventById, createEvent, updateEvent, uploadAndUpdate, getMemoryMakers, getTemplates, getClients, prepareForDeletion }

