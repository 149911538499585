import {Dispatch, SetStateAction} from 'react'

//hunnain_sc_update
// export type ID = undefined | null | number
export type ID = undefined | null | string

export type PaginationState = {
  page: number
  lastVisible?: string | null
  items_per_page: 10 | 30 | 50 | 100
  type?: string
  first_page_url?: string
  next_page_url?: string
  prev_page_url?: any
  last_page?: number
  to?: number
  total?: number
  from?: number
  history?: { [key: number]: string }
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type ViewState = {
  view?: 'gallery' | 'table'
}


export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & ViewState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  type: 'Clients',
  items_per_page: 10,
  history: {},
  filter: 'table',
  links: []
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  sessionIdForUpdate?: ID
  itemIdForDelete?: ID
  flagForDelete?: boolean
  galleryIndex?: number
  setGalleryIndex: Dispatch<SetStateAction<number>>
  setItemIdForDelete: Dispatch<SetStateAction<ID>>
  setFlagForDelete?: Dispatch<SetStateAction<boolean>>
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  setSessionIdForUpdate?: Dispatch<SetStateAction<ID>>
  setEditTableMode: Dispatch<SetStateAction<Boolean>>
  isAllSelected: boolean
  disabled: boolean
  editTableMode?: Boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setSessionIdForUpdate: () => {},
  setGalleryIndex: () => {},
  setItemIdForDelete: () => {},
  setEditTableMode: () => {},
  isAllSelected: false,
  disabled: false,
  editTableMode: false
}
