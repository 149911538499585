/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const TemplatesListSearchComponent = () => {
  const {updateState, state} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when template stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState, filter: state.filter})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <div className='card-title'>
    {/* begin::Search */}
    <div className='d-flex align-items-center position-relative my-1'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-1 position-absolute ms-6'
      />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid w-250px fs-7 ps-14'
        placeholder='Search by name, tags or id'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button
          type='button'
          className='btn btn-clear d-flex align-items-center'
          style={{marginLeft: -50}}
          onClick={() => setSearchTerm("")}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr061.svg'
            className='svg-icon-2'
          />
        </button>
      
    </div>
    {/* end::Search */}
  </div>
  )
}

export {TemplatesListSearchComponent}
