import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EventsListWrapper} from './events-list/EventsList'

const eventsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Activation Management',
    path: '/apps/event-management/events',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EventsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='events'
          element={
            <>
              <PageTitle breadcrumbs={eventsBreadcrumbs}>Activation list</PageTitle>
              <EventsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/event-management/events' />} />
    </Routes>
  )
}

export default EventsPage
