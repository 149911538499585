import { Column } from 'react-table'
import { Content } from '../../core/_models'
import { ContentCustomHeader } from './ContentCustomHeader'
import { ContentInfoCell } from './ContentInfoCell'
import { ContentLastLoginCell } from './ContentLastLoginCell'
import { ContentSelectionCell } from './ContentSelectionCell'
import { ContentSelectionHeader } from './ContentSelectionHeader'
import { ContentTwoStepsCell } from './ContentTwoStepsCell'
import { ParentInfoCell } from './ParentInfoCell'
import { ContentEnabledDisabled } from './ContentEnabledDisabled'
import { SessionCell } from './SessionCell'
import { TemplateType } from './TemplateType'

const contentsColumns: ReadonlyArray<Column<Content>> = [
  {
    Header: (props) => <ContentSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <ContentSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Creation Date' className='min-w-125px' />
    ),
    id: 'dateCreated',
    Cell: ({ ...props }) => <ContentLastLoginCell last_login={props.data[props.row.index].dateCreated} />,
  },
  // {
  //   Header: (props) => (
  //     <ContentCustomHeader tableProps={props} title='Last Modified' className='min-w-125px' />
  //   ),
  //   id: 'updatedOn',
  //   Cell: ({ ...props }) => <ContentLastLoginCell last_login={props.data[props.row.index].updatedOn} />,
  // },
  {
    Header: (props) => <ContentCustomHeader tableProps={props} title='Preview' className='min-w-125px' />,
    id: 'composite_name',
    Cell: ({ ...props }) => <ContentInfoCell content={props.data[props.row.index]} index={props.row.index} />,
  },

  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Activation' className='min-w-125px' />
    ),
    id: 'composite_activations',
    Cell: ({ ...props }) => <ParentInfoCell parent={props.data[props.row.index].activations?.[0]} navigation={{ id: props.data[props.row.index].activations?.[0].id, path: "event" }} />,
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Memory Maker' className='min-w-125px' />
    ),
    id: 'composite_parent',
    Cell: ({ ...props }) => <ParentInfoCell parent={props.data[props.row.index].parent} navigation={{ id: props.data[props.row.index].parent?.id, path: "user" }} />,
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Template Type' className='min-w-125px' />
    ),
    id: 'composite_templateType',
    Cell: ({ ...props }) => <TemplateType type={props.data[props.row.index].templateType} />,
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Session' className='min-w-125px' />
    ),
    id: 'composite_sessionNumber',
    Cell: ({ ...props }) => <SessionCell session={props.data[props.row.index].session} sessionNumber={props.data[props.row.index].sessionNumber} />,
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Consent (DOOH)' className='min-w-125px' />
    ),
    id: 'isDooh',
    Cell: ({ ...props }) => <ContentEnabledDisabled
      value={props.data[props.row.index].isDooh}
      YesNo={true}
      url={""} />,
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='DOOH' className='min-w-125px' />
    ),
    id: 'composite_dooh',
    Cell: ({ ...props }) => <ContentEnabledDisabled
      value={props.data[props.row.index].dooh}
      YesNo={false}
      url={""} />,
  },
  // {
  //   Header: (props) => (
  //     <ContentCustomHeader tableProps={props} title='Template' className='min-w-125px' />
  //   ),
  //   accessor: 'template',
  // },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Qrs Codes' className='min-w-125px' />
    ),
    id: 'composite_qrCodes',
    Cell: ({ ...props }) => <ContentTwoStepsCell tags={props.data[props.row.index].qrCodes} />,
  },
  // {
  //   Header: (props) => (
  //     <ContentCustomHeader tableProps={props} title='Emails' className='min-w-125px' />
  //   ),
  //   id: 'composite_emails',
  //   Cell: ({ ...props }) => <ContentTwoStepsCell tags={props.data[props.row.index].emails} />,
  // },
  // {
  //   Header: (props) => (
  //     <ContentCustomHeader tableProps={props} title='Id' className='min-w-125px' />
  //   ),
  //   id: 'composite_id',
  //   accessor: 'id',
  // },
  // {
  //   Header: (props) => (
  //     <ContentCustomHeader tableProps={props} title='Prints' className='min-w-125px' />
  //   ),
  //   accessor: 'prints',
  // },

  // {
  //   Header: (props) => (
  //     <ContentCustomHeader tableProps={props} title='Photoroom' className='min-w-125px' />
  //   ),
  //   accessor: 'photoroom',
  // },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Mails' className='min-w-125px' />
    ),
    accessor: 'mails',
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Size' className='min-w-125px' />
    ),
    accessor: 'size',
  },
  {
    Header: (props) => (
      <ContentCustomHeader tableProps={props} title='Storywall' className='min-w-125px' />
    ),
    id: 'composite_isMicrosite',
    Cell: ({ ...props }) => (
      <ContentEnabledDisabled
        value={
          (props.data[props.row.index].qrCodes?.length && props.data[props.row.index].templateType !== 'sticker')
            ? 'true'
            : 'false'
        }

        url={
          props.data[props.row.index].qrCodes?.length
            ? `http://yourstorywall.com/content/${props.data[props.row.index].qrCodes[0]}`
            : ''
        }
      />
    ),
  }
]

export { contentsColumns }

