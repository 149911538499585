import { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'
import { KTCard, KTSVG, QUERIES } from '../../../../../_metronic/helpers'
import { TemplatesListHeader } from './components/header/TemplatesListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from './core/QueryResponseProvider'
import { archiveSelectedTemplates, archiveTemplate, prepareForDeletion } from './core/_requests'
import { TemplatesTable } from './table/TemplatesTable'
import { TemplateEditModal } from './template-edit-modal/TemplateEditModal'

// import "lightgallery/css/lg-rotate.css"
// import "lightgallery/css/lg-zoom.css"
// import rotate from 'lightgallery/plugins/rotate'
// import share from 'lightgallery/plugins/share'
import "lightgallery/css/lg-fullscreen.css"
import "lightgallery/css/lg-share.css"
import "lightgallery/css/lg-thumbnail.css"
import "lightgallery/css/lightgallery.css"
import fullscreen from 'lightgallery/plugins/fullscreen'
import thumbnail from 'lightgallery/plugins/thumbnail'
import zoom from 'lightgallery/plugins/zoom'
import LightGallery from 'lightgallery/react'
import './style.css'

const TemplatesList = () => {
  const { itemIdForUpdate, itemIdForDelete, flagForDelete, setFlagForDelete, setItemIdForDelete, selected, clearSelected, setItemIdForUpdate } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()

  const [warningMessage, setWarningMessage] = useState(Object);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const edit = queryParams.get('edit');

  useEffect(() => {

    if (edit) {
      setItemIdForUpdate(edit);
    }

    const fetchData = async () => {
      if (itemIdForDelete) {
        try {
          const info = await prepareForDeletion(itemIdForDelete);
          if (info) {
            setWarningMessage(info);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [itemIdForDelete]);

  const deleteItem = useMutation(() => archiveTemplate(itemIdForDelete), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      setItemIdForDelete(null)
    },
  })

  const deleteSelectedItems = useMutation(() => archiveSelectedTemplates(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CONTENTS_LIST}-${query}`])
      setFlagForDelete?.(false)
      clearSelected()
    },
  })

  const lightGallery = useRef<any>(null)
  const { galleryIndex, setGalleryIndex } = useListView()
  const { updateState } = useQueryRequest()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const data = useQueryResponseData()


  const handleSlideChange = (index: number, previous: boolean) => {
    const updatePage = (page: number | null, lastVisible: any) => {
      if (!page || isLoading || pagination.page === page) {
        return
      }
      updateState({ page, items_per_page: pagination.items_per_page || 10, lastVisible: lastVisible, from: pagination.page, history: pagination.history })
      // setGalleryIndex(-2)
    }

    if (index === 0 && !previous) {
      if (pagination.links) {
        updatePage(pagination.links[pagination.links.length - 1]?.page, pagination.lastVisible)
      }
    }

    if (index === data.length - 1 && previous) {
      if (pagination.links) {
        updatePage(pagination.links[0]?.page, pagination.lastVisible)
      }
    }

  }

  const previousGalleryIndex = useRef<number | undefined>(galleryIndex)
  const previousData = useRef<any>(data)

  const openGallery = useCallback(() => {
    lightGallery.current.openGallery()
  }, [])

  useEffect(() => {
    if (previousData.current !== data) {
      previousData.current = data
      if (galleryIndex === -3) {
        setGalleryIndex(0)
        previousGalleryIndex.current = 0
      }
    }

    if (previousGalleryIndex.current !== galleryIndex) {
      previousGalleryIndex.current = galleryIndex
      if (galleryIndex !== undefined && galleryIndex > -1) {
        openGallery()
      }
    }
  }, [data, galleryIndex])

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance
    }
  }, [])


  return (
    <>
      <KTCard>
        {/* <div className={"p-5 d-flex "}>
          <Light />
        </div> */}
        <TemplatesListHeader />
        <LightGallery
          // elementClassNames="custom-classname"
          thumbnail
          index={galleryIndex}
          currentPagerPosition='right'
          mode='lg-slide'
          appendSubHtmlTo='.lg-outer'
          defaultCaptionHeight={100}
          width={'400px'}
          height={'400px'}
          allowMediaOverlap={true}
          toggleThumb={true}
          commentsMarkup={"60%"}
          appendThumbnailsTo='.lg-components'
          alignThumbnails='middle'
          dynamic
          onAfterClose={() => {
            if (isLoading) {
              setGalleryIndex(-3)
            }
            else {
              setGalleryIndex(-1)
            }
          }}
          // dynamicEl={items}
          // onAfterSlide={(event) => handleSlideChange(event)}
          onBeforePrevSlide={(item) => handleSlideChange(item.index, true)}
          onBeforeNextSlide={(item) => handleSlideChange(item.index, false)}
          dynamicEl={data.map((item, index) => ({
            src: item.original,
            thumb: item.original,
          }))}
          onInit={onInit}
          plugins={[thumbnail, fullscreen, zoom]}
        />
        <TemplatesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TemplateEditModal />}
      {(flagForDelete || itemIdForDelete) && warningMessage &&
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              {/* begin::Modal template */}
              <div className='modal-content'>
                <div
                  className='modal fade show d-block'
                  id='kt_modal_add_user'
                  role='dialog'
                  tabIndex={-1}
                  aria-modal='true'>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Archive Template</h5>
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setFlagForDelete?.(false)
                            setItemIdForDelete(null)
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                          />
                        </div>
                      </div>

                      <div className="modal-body">
                        Press confirm to delete.
                        <div style={{ marginTop: 15 }}>
                          {'\n\n'}
                          {flagForDelete && "Note: You are performing combined delete operation. This might cause oversight."}
                          {!flagForDelete && (
                            <div className="modal-body">
                              Note:
                              <ul style={{ listStyleType: 'none', paddingInlineStart: 0, marginTop: '5px' }}>
                                <li>{`- ${warningMessage.activations} Activations are associated with this template.`}</li>
                                <li>{`- ${warningMessage.contents} Contents are created for this template.`}</li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setFlagForDelete?.(false)
                            setItemIdForDelete(null)
                          }}
                        >
                          Cancel
                        </button>
                        <button type="button" className="btn btn-primary"
                          onClick={async () => {
                            if (itemIdForDelete) {
                              deleteItem.mutateAsync()
                            }
                            else if (selected.length && flagForDelete) {
                              await deleteSelectedItems.mutateAsync()
                            }
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Modal template */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'
            onClick={() => {
              setFlagForDelete?.(false)
              setItemIdForDelete(null)
            }}
          />
          {/* end::Modal Backdrop */}
        </>
      }
    </>
  )
}

const TemplatesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TemplatesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { TemplatesListWrapper }

