/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {App} from '../../core/_models'

type Props = {
  app: App
}

const AppInfoCell: FC<Props> = ({app}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-oval symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {app.avatar ? (
          <div className='symbol-label'>
            <img src={app.avatar} alt={app.title} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3 text-uppercase',
              `bg-light-${app.title}`,
              `text-${app.title}`
            )}
          >
            {app.title? app.title[0]:""}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {app.title}
      </a>
      {/* <span>{app.subtitle}</span> */}
    </div>
  </div>
)

export {AppInfoCell}
