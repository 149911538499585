/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { Content } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  content: Content
  index: number
}

const ContentInfoCell: FC<Props> = ({ content, index }) => {
  const { setGalleryIndex, galleryIndex } = useListView()
  const { setItemIdForUpdate, setFlagForDelete } = useListView()

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div onClick={() => {
        // setGalleryIndex(index)
        setItemIdForUpdate(content.id)
      }} className='symbol symbol-oval symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {content.avatar ? (
            // <div className='symbol-label shadow-lg'>
            //   <img src={content.avatar} alt={content.name} className='img-fluid shadow-' />
            // </div>
            <div className='symbol-label'>
              <img src={content.avatar} alt={content.name} className='w-100' />
            </div>
          ) :
            content.video ? (
              <div className='symbol-label' style={{backgroundColor: '#5d78ff'}}>
                {/* <img src={toAbsoluteUrl('/media/svg/misc/video-play.svg')} alt={content.name} className='w-100' style={{fill: 'red'}} /> */}

                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='play-icon position-absolute top-50 start-50 translate-middle'
                  viewBox='0 0 20 24'
                  width='24'
                  // height='28'
                >
                  <path
                    fill='#ffffff' // Set the fill color here
                    d='M16 12l-10 8v-16z'
                  />
                </svg>

              </div>
            )
              :
              <div
                className={clsx(
                  'symbol-label fs-3',
                  `bg-light-${content.name}`,
                  `text-${content.name}`
                )}
              >
                {content.id}
              </div>
          }
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {content.name}
        </a>
        <span>{content.email}</span>
      </div>
    </div>
  )
}

export { ContentInfoCell }
