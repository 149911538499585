/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const ClientsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (page: number | null, lastVisible: any) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({ page, items_per_page: pagination.items_per_page || 10, lastVisible: lastVisible, from: pagination.page, history: pagination.history })
  }



  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_events_paginate'>
          <ul className='pagination'>
            {pagination.links
              ?.map((link) => {
                return { ...link, label: mappedLabel(link.label) }
              })
              .map((link, index) => (
                <li
                  key={link.label}
                  className={clsx('page-item paginate_button', {
                    active: pagination.page === link.page,
                    disabled: isLoading || (link.label === 'Next' && (pagination.page === link.page)) || (link.label === 'Previous' && pagination.page === 1 ),
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      // 'me-5': link.label === 'Previous',
                    })}
                    onClick={() => {
                      if(link.label === 'Previous' || pagination.page > (1 * link.page!)) {
                        updatePage(link.page, null)
                      }
                      else {
                        updatePage(link.page, pagination.lastVisible)
                      }
                      
                    }}
                    style={{ cursor: 'pointer', fontSize: pagination.page === link.page ? 18 : 12, borderRadius: 20, }}
                  >
                    {
                      link.label === 'Next' ?
                        <i className="next" />
                        :
                        link.label === 'Previous' ?
                          <i className="previous" />
                          :
                          mappedLabel(link.label)
                    }

                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>

    </div>
  )
}

export { ClientsListPagination }
