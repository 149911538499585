import { getDocs, getDoc, deleteDoc, startAfter, limit, getFirestore, setDoc, doc, collection, query as firebaseQuery, where, updateDoc, Timestamp, orderBy } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { Template, TemplatesQueryResponse } from './_models'
import { getDateTime, getDate, convertSelectToArr, removeEmptyAttributes, getBoolean, checkForBool, simpleSubsequencesForObject, checkIfIndexRequired } from '../../../../../../jsFunctions'
import { getValueByNumber, generatePaginationLinks, downloadFile, getUniqueFileName } from '../../../../../../tsFunctions'
import JSZip from 'jszip'
const API_URL = process.env.REACT_APP_THEME_API_URL
const TEMPLATE_URL = `${API_URL}/user`
const collection_name = "_templates";
const CONTENT_URL = `${API_URL}/user`
const GET_CONTENTS_URL = `${API_URL}/users/query`
interface DynamicObject {
  [key: string]: any;
}


const getTemplates = (query: string): Promise<TemplatesQueryResponse> => {
  const db = getFirestore()
  let params = new URLSearchParams(query);

  let sort = params.get('sort')
  let lastVisible = params.get('lastVisible')
  let page: number = Number(params.get('page'))
  let from: number = Number(params.get('from'))
  let search = params.get('search')?.toLowerCase()
  let order = params.get('order')
  let history = params.get('history')
  interface Filter {
    type: string; // replace with the actual types
    status: string
    // other properties...
  }
  let filter: Filter = Object({ type: params.get('filter_type'), status: params.get('filter_status') })

  const parseHistoryObj = (historyString: string) => {
    if (!historyString) return [];
    return JSON.parse(historyString);
  };

  const parsedHistory = parseHistoryObj(history as string);

  let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);

  if (!lastVisible && startParam.value) {
    lastVisible = startParam.value
  }

  let q = firebaseQuery(collection(db, collection_name), limit(6));
  if (lastVisible && page > 1) {
    const counter = page - from;
    const pageLimit = 10;
    let entries = pageLimit * counter + 1;
    if (from > page) {
      let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);
      if (startParam.value) {
        lastVisible = startParam.value ? startParam.value : lastVisible
      }
      if (page === startParam.key) {
        entries = pageLimit + 1;
      }
      else {
        entries = pageLimit * (page - startParam.key) + 1;
      }
    }

    return getDoc(doc(db, collection_name, lastVisible)).then((docSnap) => {
      if (sort && (order === "desc" || order === "asc") && search) {
        q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), startAfter(docSnap), limit(entries))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), where(filter.type, "==", checkForBool(filter.status)), orderBy(sort, order), startAfter(docSnap), limit(entries))
        }
      }
      else if (sort && (order === "desc" || order === "asc")) {
        q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), startAfter(docSnap), limit(entries))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), where(filter.type, "==", checkForBool(filter.status)), startAfter(docSnap), limit(entries))
        }
      }
      else if (search) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), startAfter(docSnap), limit(entries))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where(filter.type, "==", checkForBool(filter.status)), where("zearch", "array-contains", search), startAfter(docSnap), limit(entries))
        }
      }
      else {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(entries), startAfter(docSnap))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where(filter.type, "==", checkForBool(filter.status)), limit(entries), startAfter(docSnap))
        }
      }

      return new Promise(resolve =>
        getDocs(q)
          .then(querySnapshot => {
            const templatesArr = Array()

            let docs = querySnapshot.docs.slice();
            let landingPage = Math.ceil((querySnapshot.size - 1) / pageLimit);
            if (querySnapshot.size === 1) {
              landingPage = 1;
            }
            if (page > from) {
              page = from + landingPage;
            }
            //remove entries for pages that are not required
            if (landingPage > 1) {
              const toSplice = ((landingPage - 1) * pageLimit);
              docs.splice(0, toSplice);
            }
            //remove one extra entry if page is not last page
            if (docs.length > pageLimit) {
              docs.splice(-1);
              lastVisible = docs[docs.length - 1].id;
            }
            else {
              lastVisible = null;
            }

            docs.forEach(document => {
              const user = document.data()

              templatesArr.push({
                id: document.id,
                name: user.name,
                dateCreated: getDateTime(user.dateCreated.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
                avatar: user.image ? user.image : user.raw,
                video: user.video ? user.video : null,
                original: user.image ? user.image : user.raw,
                microsite: getBoolean(user.microsite, true),
                uploadAllowed: getBoolean(user.uploadAllowed, true),
                dooh: getBoolean(user.dooh, true),
                print: getBoolean(user.print, true),
                // magic: getBoolean(user.magic, true),
                // isSticker: getBoolean(user.isSticker, true),
                type: user.magic ? user.isSticker ? "sticker" : "magic" : "classic",
                disable: getBoolean(user.disable, true),

                tags: user.qrCodes,
                mails: user.mails,
                template: user.template,

                sessionNumber: user.sessionNumber,
                // polaroid: user.polaroid,
                raw: user.raw,
                parent: user.parent,
                info: user.info,
                projectNo: user.projectNo,

              })
            })

            page = templatesArr.length ? page : 1

            resolve({
              data: templatesArr,
              payload: {
                message: "N/A",
                errors: {},
                "pagination": {
                  page: page,
                  items_per_page: 10,
                  lastVisible: lastVisible,
                  "first_page_url": "/?page=1",
                  "from": 1,
                  "last_page": page,
                  "next_page_url": "\\/?page=" + (page + 1),
                  "prev_page_url": null,
                  "to": querySnapshot.docs.length + 1,
                  // "total": 21,
                  "history": { ...parsedHistory, [page]: templatesArr.length === pageLimit ? templatesArr[pageLimit - 1].id : null },
                  "links": generatePaginationLinks(page, lastVisible)
                }
              }
            })
          }).catch(error => {
            console.log(error)
            checkIfIndexRequired(error.message)
            resolve(error)
          }))

    })
  }
  else {
    const pageLimit = 10 * page;

    if (sort && (order === "desc" || order === "asc") && search) {
      q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), where(filter.type, "==", checkForBool(filter.status)), orderBy(sort, order), limit(pageLimit + 1))
      }
    }
    else if (sort && (order === "desc" || order === "asc")) {
      q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), where(filter.type, "==", checkForBool(filter.status)), limit(pageLimit + 1))
      }
    }
    else if (search) {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), where(filter.type, "==", checkForBool(filter.status)), limit(pageLimit + 1))
      }
    }
    else {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(pageLimit + 1))

      console.log(filter.status)

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where(filter.type, "==", checkForBool(filter.status)), limit(pageLimit + 1))
      }
    }

    return new Promise(resolve =>
      getDocs(q)
        .then(querySnapshot => {
          const templatesArr = Array()
          let index = 0;

          querySnapshot.forEach(document => {
            if (pageLimit > 10 && index < pageLimit - 10) {

            }
            else {
              const user = document.data()

              templatesArr.push({
                id: document.id,
                name: user.name,
                dateCreated: getDateTime(user.dateCreated.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
                avatar: user.image ? user.image : user.raw,
                video: user.video ? user.video : null,
                original: user.image ? user.image : user.raw,
                microsite: getBoolean(user.microsite, true),
                uploadAllowed: getBoolean(user.uploadAllowed, true),
                dooh: getBoolean(user.dooh, true),
                print: getBoolean(user.print, true),
                // magic: getBoolean(user.magic, true),
                // isSticker: getBoolean(user.isSticker, true),
                type: user.magic ? user.isSticker ? "sticker" : "magic" : "classic",
                disable: getBoolean(user.disable, true),
                tags: user.qrCodes,
                mails: user.mails,
                template: user.template,
                raw: user.raw,
                parent: user.parent,
                info: user.info,
                projectNo: user.projectNo,

              })
            }
            index++
          })

          page = templatesArr.length ? page : 1

          lastVisible = templatesArr.length > pageLimit ? templatesArr[templatesArr.length - 2].id : null

          resolve({
            data: templatesArr.length > pageLimit ? templatesArr.slice(0, templatesArr.length - 1) : templatesArr,
            payload: {
              message: "N/A",
              errors: {},
              "pagination": {
                page: page,
                items_per_page: 10,
                lastVisible: lastVisible,
                "first_page_url": "/?page=1",
                "from": 1,
                // "last_page": 3,
                "next_page_url": "\\/?page=" + (page + 1),
                "prev_page_url": "\\/?page=" + (page - 1),
                "to": querySnapshot.docs.length + 1,
                // "total": 21,
                "history": { ...parsedHistory, [page]: templatesArr.length > pageLimit ? templatesArr[pageLimit - 1].id : null },
                "links": generatePaginationLinks(page, lastVisible)
              }
            }
          })
        }).catch(error => {
          console.log(error)
          checkIfIndexRequired(error.message)
          resolve(error)
        }))
  }

}

const getTemplateById = (id: ID): Promise<Template | undefined> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, collection_name))
  q = firebaseQuery(collection(db, collection_name), where("id", "==", id))

  console.log(id)

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {
        const usersArr = Array()
        querySnapshot.forEach(doc => {
          // console.log(`${doc.id} => ${doc.data()}`)
          const user = doc.data()

          usersArr.push({
            id: doc.id,
            dateCreated: getDateTime(user.dateCreated.seconds * 1000),
            dimensions: user.dimensions,
            updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(new Date()),
            microsite: getBoolean(user.microsite, false),
            uploadAllowed: getBoolean(user.uploadAllowed, false),
            dooh: getBoolean(user.dooh, false),
            print: getBoolean(user.print, false),
            disable: getBoolean(user.disable, false),
            // isSticker: getBoolean(user.isSticker, false),
            // magic: getBoolean(user.magic, false),
            type: user.magic ? user.isSticker ? "sticker" : "magic" : "classic",
            tags: user.tags,
            parent: user.parent?.label,
            projectNo: user.projectNo,
            info: user.info,
            name: user.name,
            avatar: user.image ? user.image : user.raw,
            video: user.video ? user.video : null,
            original: user.image ? user.image : user.raw,
          })
        })
        resolve(usersArr[0])
      }).catch(error => {
        console.error(error)
        resolve(error)
      }))
}

const uploadAndUpdate = (id: string, avatar: any, name: string) => {
  const db = getFirestore()
  const storage = getStorage()
  const storageRef = ref(storage, '/panelTemplates/dp/' + name)
  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, avatar).then((snapshot) => {
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      updateDoc(doc(db, collection_name, id), {
        avatar: downloadURL,
        updatedOn: new Date()
      });

      // updateUser({ id: panelUser.id, avatar: downloadURL })
    })
  })
}


const updateTemplate = (template: Template): Promise<Template | undefined> => {
  const db = getFirestore()
  if (template.id) {

    const _templates = doc(db, collection_name, template.id)

    template.avatar = null
    template.dateCreated = undefined
    const fTemplate = removeEmptyAttributes(template)

    const obj: DynamicObject = {};

    obj[fTemplate.name] = fTemplate.name;

    const tags = convertSelectToArr(template.tags);

    if (tags?.length) {
      tags.forEach((item: string) => {
        obj[item] = item;
      });
    }

    const subsequencesArray = simpleSubsequencesForObject(obj);

    return new Promise(resolve => setDoc(_templates, { ...fTemplate, zearch: subsequencesArray.concat([fTemplate.id.toLowerCase()]), isSticker: template.type === 'sticker', magic: template.type !== 'classic', tags: tags, updatedOn: new Date() }, { merge: true })
      .then((response) => {
        console.log(response)
        resolve(template)
      }).catch(error => {
        console.log(error)
        resolve(undefined)
      }))
  }
  else {
    return new Promise(resolve =>
      resolve(undefined)
    )
  }
}

const createTemplate = (template: Template): Promise<Template | undefined> => {
  const db = getFirestore()
  if (template.id) {
    const _templates = doc(db, collection_name, template.id)

    template.avatar = null

    template = removeEmptyAttributes(template)

    return new Promise(resolve => setDoc(_templates, { avatar: template.avatar, name: template.name, tags: convertSelectToArr(template.tags), info: template.info }, { merge: true })
      .then((response) => {
        console.log(response)
        resolve(template)
      }).catch(error => {
        console.log(error)
        resolve(undefined)
      }))
  }
  else {
    return new Promise(resolve =>
      resolve(undefined)
    )
  }
}

type Info = {
  activations: number;
  contents: number;
};

const prepareForDeletion = (id: ID): Promise<Info> => {
  const db = getFirestore();

  const q = firebaseQuery(collection(db, "_events"), where('templates', 'array-contains', id));
  const q2 = firebaseQuery(collection(db, "_contents"), where('template', '==', id));

  const obj: Info = {
    activations: 0,
    contents: 0,
  };

  // Use Promise.all to wait for both asynchronous calls to complete
  return Promise.all([getDocs(q), getDocs(q2)])
    .then(([activationsSnapshot, contentsSnapshot]) => {
      obj.activations = activationsSnapshot.size;
      obj.contents = contentsSnapshot.size;

      return obj;
    })
    .catch((error) => {
      console.error(error);
      throw error; // Rethrow the error to propagate it further if needed
    });
};

const archiveSelectedTemplates = (selectedIds: Array<ID>): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  // Use Promise.all to run the promises concurrently
  const archivePromises = selectedIds.map(documentId => {
    if (documentId) {
      const userDocRef = doc(db, collection_name, documentId);

      // Retrieve the document data
      return getDoc(userDocRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();

            // Set the document data in the new collection
            const archiveDocRef = doc(db, collectionName, documentId);
            return setDoc(archiveDocRef, userData)
              .then(() => {
                // Delete the document from the original collection
                return deleteDoc(userDocRef);
              })
              .catch((error) => {
                console.error("Error archiving template:", error);
                throw error;
              });
          } else {
            throw new Error(`document with ID ${documentId} not found.`);
          }
        })
        .catch((error) => {
          console.error(`Error retrieving document with ID ${documentId}:`, error);
          throw error;
        });
    } else {
      return Promise.reject(new Error("Invalid ID"));
    }
  });

  // Return a promise that resolves when all archive operations are complete
  return Promise.all(archivePromises).then(() => { });
};

const exportSelectedTemplates = (selectedIds: Array<ID>): Promise<void> => {
  const db = getFirestore();
  const zip = new JSZip();

  // Use Promise.all to run the promises concurrently
  const downloadPromises = selectedIds.map(documentId => {
    if (documentId) {
      const userDocRef = doc(db, collection_name, documentId);

      // Retrieve the document data
      return getDoc(userDocRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();
            const imageUrl = userData?.original ? userData.original : userData.image; // Assuming the image parameter is named "image"

            if (imageUrl) {
              // Download image and add it to the zip file
              return downloadFile(imageUrl)
                .then((blob: any) => {
                  const baseFileName = `${userData.name.toLowerCase()}-${userData.type}.png`;
                  const fileName = getUniqueFileName(zip, baseFileName);
                  zip.file(fileName, blob);
                })
                .catch((error) => {
                  console.error(`Error downloading image for document with ID ${documentId}:`, error);
                  throw error;
                });
            }
            else if (userData.video) {
              return downloadFile(userData.video)
                .then((blob: any) => {
                  const baseFileName = `${userData.name.toLowerCase()}-${userData.type}.mp4`;
                  const fileName = getUniqueFileName(zip, baseFileName);
                  zip.file(fileName, blob);
                })
                .catch((error) => {
                  console.error(`Error downloading image for document with ID ${documentId}:`, error);
                  throw error;
                });
            }
            else {
              console.warn(`Image not found in document with ID ${documentId}`);
              return null;
            }
          } else {
            throw new Error(`Document with ID ${documentId} not found.`);
          }
        })
        .catch((error) => {
          console.error(`Error retrieving document with ID ${documentId}:`, error);
          throw error;
        });
    } else {
      return Promise.reject(new Error("Invalid ID"));
    }
  });

  // Return a promise that resolves when all download operations are complete
  return Promise.all(downloadPromises)
    .then(() => {
      // Generate and download the zip file
      zip.generateAsync({ type: 'blob' })
        .then((content) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = 'saycheese_template_exports.zip';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error generating zip file:', error);
          throw error;
        });
    });
};

const archiveTemplate = (templateId: ID): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  if (templateId) {
    const userDocRef = doc(db, collection_name, templateId);

    // Retrieve the document data
    return getDoc(userDocRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.data();

          // Set the document data in the new collection
          const archiveDocRef = doc(db, collectionName, templateId);
          return setDoc(archiveDocRef, userData)
            .then(() => {
              // Delete the document from the original collection
              return deleteDoc(userDocRef);
            })
            .catch((error) => {
              console.error("Error archiving user:", error);
              throw error;
            });
        } else {
          throw new Error("User document not found.");
        }
      })
      .catch((error) => {
        console.error("Error retrieving user document:", error);
        throw error;
      });
  } else {
    return Promise.reject(new Error("Invalid user ID"));
  }
}

export { getTemplates, archiveTemplate, archiveSelectedTemplates, getTemplateById, createTemplate, updateTemplate, uploadAndUpdate, prepareForDeletion, exportSelectedTemplates }
