import axios, { AxiosResponse } from 'axios'
import { getDocs, getFirestore, setDoc, doc, collection, query as firebaseQuery, where, Timestamp, orderBy } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { ID, Response } from '../../../../../../_metronic/helpers'
import { App, AppsQueryResponse } from './_models'
import { getDateTime } from '../../../../../../jsFunctions'

const API_URL = process.env.REACT_APP_THEME_API_URL
const APP_URL = `${API_URL}/user`
const GET_APPS_URL = `${API_URL}/users/query`

// const getApps = (query: string): Promise<AppsQueryResponse> => {
//   return axios
//     .get(`${GET_APPS_URL}?${query}`)
//     .then((d: AxiosResponse<AppsQueryResponse>) => d.data)
// }

const getApps = (query: string): Promise<AppsQueryResponse> => {
  let keys = { 'datecreated': 'dateCreated', 'title': 'name', 'name': 'name', 'Activations': '_clients', 'Collections': '_activations' };
  let keys2 = { 'datecreated': 'dateCreated', 'title': 'name', 'name': 'name', 'Activations': '_clients', 'Collections': '_activations' };
  let table = '_clients'

  const db = getFirestore()
  let params = new URLSearchParams(query);

  console.log("query ", query)
  
  let apptype = params.get('type')

  if (apptype && keys[apptype as keyof typeof keys]) {
    table = keys[apptype as keyof typeof keys]
    // alert(table)
  }

  let sort = params.get('sort')

  if (table !== '_clients') {
    keys = keys2
  }

  sort = keys[sort as keyof typeof keys]

  let search = params.get('search')
  let order = params.get('order')
  let q = firebaseQuery(collection(db, table))

  if (sort && (order === "desc" || order === "asc") && search) {
    q = firebaseQuery(collection(db, table), where(keys['name'], ">=", search), where(keys['name'], "<=", search + '\uf8ff'), orderBy(sort, order))
  }
  if (sort && (order === "desc" || order === "asc")) {
    q = firebaseQuery(collection(db, table), orderBy(sort, order))
  }
  else if (search) {
    q = firebaseQuery(collection(db, table), where(keys['name'], ">=", search), where(keys['name'], "<=", search + '\uf8ff'))
  }
  
  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {
        const usersArr = Array()
        querySnapshot.forEach(doc => {
          // console.log(`${doc.id} => ${doc.data()}`)


          const user = doc.data()

          usersArr.push({
            id: doc.id,
            last_login: getDateTime(user.dateCreated.seconds * 1000),
            dateCreated: getDateTime(user.dateCreated.seconds * 1000),
            startDate: user.startDate?new Date(user.startDate.seconds*1000):null,
            // endDate: getDateTime(user.endDate),
            // last_login: getDateTime(user.metadata.lastSignInTime),
            two_steps: true,
            // joined_day: getDateTime(user.metadata.creationTime),
            online: true,
            initials: {
              label: "labels",
              state: "state",
            },
            title: table === '_clients' ? user.name : user.name,
            password: user.password,
            subtitle: table === '_clients' ? "user.email" : "user.parent[0].label",
            first_name: user.first_name ? user.first_name : "Guest",
            last_name: user.last_name ? user.last_name : "Guest",
            fullname: user.fullname ? user.fullname : "Guest",
            occupation: user.occupation ? user.occupation : "N/A",
            companyName: user.companyName ? user.companyName : "N/A",
            phone: user.companyName ? user.companyName : "N/A",
            // roles: user.role ? user.role : "0",
            // avatar: user.image ? user.image : "N/A",
            avatar: user.avatar,
            role: user.role,
            language: 'en',
            timeZone: user.timeZone ? user.timeZone : "N/A",
            website: user.website ? user.website : "N/A",
          })
        })
        resolve({
          data: usersArr,
          payload: {
            message: "N/A",
            errors: {},

            "pagination": {
              page: 1,
              items_per_page: 10,
              "first_page_url": "/?page=1",
              "from": 1,
              "last_page": 3,
              "next_page_url": "\\/?page=2",
              "prev_page_url": null,
              "to": 10,
              "total": 21,
              "links": [{ "url": null, "label": "&laquo; Previous", "active": false, "page": null }, { "url": "\/?page=1", "label": "1", "active": true, "page": 1 }, { "url": "\/?page=2", "label": "2", "active": false, "page": 2 }, { "url": "\/?page=3", "label": "3", "active": false, "page": 3 }, { "url": "\/?page=2", "label": "Next &raquo;", "active": false, "page": 2 }]
            }
            // { "page": 2, "first_page_url": "/?page=1", "from": 1, "last_page": 3, "links": [{ "url": null, "label": "&laquo; Previous", "active": false, "page": null }, { "url": "\/?page=1", "label": "1", "active": true, "page": 1 }, { "url": "\/?page=2", "label": "2", "active": false, "page": 2 }, { "url": "\/?page=3", "label": "3", "active": false, "page": 3 }, { "url": "\/?page=2", "label": "Next &raquo;", "active": false, "page": 2 }], "next_page_url": "\/?page=2", "items_per_page": 10, "prev_page_url": null, "to": 10, "total": 21 }
          }
        })
      }).catch(error => {
        console.error(error)
        resolve(error)
      }))
}

const getAppById = (id: ID): Promise<App | undefined> => {
  return axios
    .get(`${APP_URL}/${id}`)
    .then((response: AxiosResponse<Response<App>>) => response.data)
    .then((response: Response<App>) => response.data)
}

const createApp = (app: App): Promise<App | undefined> => {
  return axios
    .put(APP_URL, app)
    .then((response: AxiosResponse<Response<App>>) => response.data)
    .then((response: Response<App>) => response.data)
}

const updateApp = (app: App): Promise<App | undefined> => {
  return axios
    .post(`${APP_URL}/${app.id}`, app)
    .then((response: AxiosResponse<Response<App>>) => response.data)
    .then((response: Response<App>) => response.data)
}

const deleteApp = (appId: ID): Promise<void> => {
  return axios.delete(`${APP_URL}/${appId}`).then(() => { })
}

const deleteSelectedApps = (appIds: Array<ID>): Promise<void> => {
  const requests = appIds.map((id) => axios.delete(`${APP_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getApps, deleteApp, deleteSelectedApps, getAppById, createApp, updateApp }
