import { useState, useEffect } from 'react'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { EventsListFilter } from './EventsListFilter'
import { CreateAppModal } from '../../../../../../../_metronic/partials'

const EventsListToolbar = () => {
  const { refetch } = useQueryResponse()
  const { state } = useQueryRequest();
  const [filterApplied, setFilterApplied] = useState<boolean>(false)
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  

  const openAddEventModal = () => {
    setShowCreateAppModal(true)
  }

  useEffect(() => {
    //hunnain ts type script learning - Using Type Assertion:
    if ((state.filter as { status?: string })?.status) {
      setFilterApplied(true)
    }
    else {
      setFilterApplied(false)
    }
  }, [state.filter]);

  return (
    <div className='d-flex justify-content-end' data-kt-event-table-toolbar='base'>
      <EventsListFilter />

      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        {filterApplied && (
          <span
            className="badge bg-primary position-absolute mt-7  p-1.5"
            style={{ fontSize: '0.8rem', cursor: 'pointer', marginLeft: '-35px' }}
          >
            1
          </span>
        )}
        Filter
      </button>

      {/* begin::Add event */}
      <button type='button' className='btn btn-primary' onClick={openAddEventModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Activation
      </button>

      <CreateAppModal type={'Activations'} show={showCreateAppModal} handleClose={() => {
        refetch()
        setShowCreateAppModal(false)
      }} />
      {/* end::Add event */}
    </div>
  )
}

export { EventsListToolbar }
