/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl, ID } from '../../../../../../../_metronic/helpers'
import { Template } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  template: Template
  index: number,
  id: ID
}

const TemplateInfoCell: FC<Props> = ({ template, index, id }) => {
  const { setGalleryIndex, setItemIdForUpdate } = useListView()
  
  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div onClick={openEditModal} className='symbol symbol-oval symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {!template.video ? (
            <div className='symbol-label shadow-lg'>
              <img src={template.avatar} alt={template.name} className='img-fluid shadow-' />
            </div>
          ) : 
          template.video ? (
            <div className='symbol-label' style={{backgroundColor: '#5d78ff'}}>
              {/* <img src={toAbsoluteUrl('/media/svg/misc/video-play.svg')} alt={template.name} className='w-100' style={{fill: 'red'}} /> */}

              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='play-icon position-absolute top-50 start-50 translate-middle'
                viewBox='0 0 20 24'
                width='24'
                // height='28'
              >
                <path
                  fill='#ffffff' // Set the fill color here
                  d='M16 12l-10 8v-16z'
                />
              </svg>

            </div>
          )
            :
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${template.name}`,
                `text-${template.name}`
              )}
            >
              {template.id}
            </div>
          // (
          //   <div
          //     className={clsx(
          //       'symbol-label fs-3',
          //       `bg-light-${template.name}`,
          //       `text-${template.name}`
          //     )}
          //   >
          //     {template.name?.[0]}
          //   </div>
          // )
          }
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {template.name}
        </a>
      </div>
    </div>
  )
}

export { TemplateInfoCell }
