import {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ClientsListFilter} from './ClientsListFilter'
import { CreateAppModal } from '../../../../../../../_metronic/partials'

const ClientsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddClientModal = () => {
    // setShowCreateAppModal(true)
    setItemIdForUpdate(null)
  }
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  return (
    <div className='d-flex justify-content-end' data-kt-client-table-toolbar='base'>
      <ClientsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add client */}
      <button type='button' className='btn btn-primary' onClick={openAddClientModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Client
      </button>

      <CreateAppModal type={'Clients'} show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />

      {/* end::Add client */}
    </div>
  )
}

export {ClientsListToolbar}
