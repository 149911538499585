//hunnain this is chat gpt modified code, and adds the keys to useQuery
import { useQuery } from 'react-query';
import { EventEditModalForm } from './EventEditModalForm';
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';
import {
  getEventById,
  getMemoryMakers,
  getTemplates,
  getClients,
} from '../core/_requests';

const EventEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);

  const {
    isLoading,
    data: event,
    error,
  } = useQuery(
    ['event', itemIdForUpdate], // Use a descriptive key
    () => {
      return getEventById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
    }
  );

  const {
    data: brands,
  } = useQuery(
    ['clientsForEvents'], // Use a descriptive key
    () => {
      return getClients();
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    data: memoryMakers,
  } = useQuery(
    ['memoryMakers'], // Use a descriptive key
    () => {
      return getMemoryMakers(false);
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    data: templates,
  } = useQuery(
    ['templates'], // Use a descriptive key
    () => {
      return getTemplates();
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  if (!itemIdForUpdate && memoryMakers && templates && brands) {
    return (
      <EventEditModalForm
        isEventLoading={isLoading}
        event={{ id: undefined }}
        clients={brands}
        memoryMakers={memoryMakers}
        templates={templates}
      />
    );
  }

  if (!isLoading && !error && event && memoryMakers && templates && brands) {
    return (
      <EventEditModalForm
        isEventLoading={isLoading}
        event={event}
        clients={brands}
        memoryMakers={memoryMakers}
        templates={templates}
      />
    );
  }

  return null;
};

export { EventEditModalFormWrapper };
