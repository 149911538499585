import { FC } from 'react'

type Props = {
    type?: 'classic' | 'magic' | 'sticker'
}

const TemplateType: FC<Props> = ({ type }) => (
  <div className={`badge ${type=="magic"? "badge-light-info":type=="sticker"?"badge-light-warning":"badge-light-primary"} fw-bolder m-1`}>{type}</div>
)

export { TemplateType }
