/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components';
import { ID, KTSVG, QUERIES } from '../../../../../../../_metronic/helpers';
import { useListView } from '../../core/ListViewProvider';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import { archiveEvent } from '../../core/_requests';
import { isURL, duplicateDocument } from '../../../../../../../jsFunctions';

type Props = {
  id: ID;
  embedCode?: any;
  eventName?: string
};

const EventActionsCell: FC<Props> = ({ id, embedCode, eventName }) => {
  const { setItemIdForUpdate, setItemIdForDelete } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  const duplicate = useMutation(() => duplicateDocument(id, "_activations"), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EVENTS_LIST}-${query}`]);
    },
  });

  const deleteItem = useMutation(() => archiveEvent(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EVENTS_LIST}-${query}`]);
    },
  });

  const handleButtonClick = () => {
    // openIframeInNewTab(embedCode)
    // navigate(`/apps/event-management/${eventName}/report?link=${embedCode}&name=${eventName}`);

    if (isURL(embedCode)) {
      const url = `/apps/event-management/${eventName}/report?link=${embedCode}&name=${eventName}`;
      // Open in a new tab
      window.open(url, '_blank');
    }
    else {
      alert("No report available.")
    }


  };

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3'  onClick={async () => await duplicate.mutateAsync()}>
            Duplicate
          </a>
        </div> */}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={handleButtonClick}>
            Studio Report
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-events-table-filter='delete_row'
            onClick={() => setItemIdForDelete(id)}
          // onClick={async () => await deleteItem.mutateAsync()}
          >
            Archive
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { EventActionsCell };
