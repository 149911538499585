/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { StepProps } from '../IAppModels'

const Step2 = ({ data, updateData, hasError }: StepProps) => {
  const [startDate, setStartDate] = useState<any>(new Date());
  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}

          {/* begin::Label */}
          {/* end::Label */}

          {/* begin::Input */}
          {/* <input
              placeholder='Date'
              {...formik.getFieldProps('date')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='date'
              name='date'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            /> */}

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Title</label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='title'
              value={data.name}
              onChange={(e) =>
                updateData({
                  name: e.target.value,
                  // projectNo: data.projectNo,
                  // startDate: data.startDate,
                  // type: data.type,
                  // info: data.info,
                })
              }
            />
            {!data.name && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='name' data-validator='notEmpty' className='fv-help-block'>
                  Title is required
                </div>
              </div>
            )}
          </div>



          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Start Date</label>
            <DatePicker
              showPopperArrow={false}
              showTimeSelect={data.type === 'Activations'}
              className={
                'form-control form-control-solid mb-3 mb-lg-0'
              }
              timeFormat="HH:mm"
              dateFormat={data.type === 'Collections' ? "MMMM d, yyyy" : "HH:mm MMMM d, yyyy"}
              minDate={new Date()}
              selected={data.startDate}
              onChange={(startDate) => {
                if (startDate) {
                  const oneDayAfter = new Date(startDate);
                  oneDayAfter.setDate(startDate.getDate() + 1);
                  updateData({ startDate: startDate, endDate: data.type === 'Activations' ? oneDayAfter : null })
                }
              }}
            />
            {!data.startDate && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='name' data-validator='notEmpty' className='fv-help-block'>
                  Start date is required
                </div>
              </div>
            )}
          </div>



          <div className='fv-row mb-7'>
            <label className=' fw-bold fs-6 mb-2'>End Date</label>
            <DatePicker
              showPopperArrow={false}
              showTimeSelect={data.type === 'Activations'}
              className={
                'form-control form-control-solid mb-3 mb-lg-0'
              }
              timeFormat="HH:mm"
              dateFormat={data.type === 'Collections' ? "MMMM d, yyyy" : "HH:mm MMMM d, yyyy"}
              minDate={data.startDate}
              selected={data.endDate}
              onChange={(endDate) => updateData({ endDate: endDate })}
            />
          </div>

          <div className='fv-row mb-7'>
            <label className=' fw-bold fs-6 mb-2'>Project #</label>
            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Project #'
              value={data.projectNo}
              onChange={(e) =>
                updateData({
                  projectNo: e.target.value,
                  // name: data.startDate,
                  // startDate: data.startDate,
                  // type: data.type,
                  // info: data.info,
                })
              }
            />
          </div>

          {/* end::Input */}


          <div className='fv-row mb-7'>
            <label className=' fw-bold fs-6 mb-2'>Note</label>

            <textarea
              rows={3}
              aria-multiline
              className='form-control form-control-lg form-control-solid'
              placeholder='extra info here'
              value={data.info}
              onChange={(e) =>
                updateData({
                  info: e.target.value,
                  // startDate: data.startDate,
                  // projectNo: data.projectNo,
                  // type: data.type,
                  // name: data.name,
                })
              }
            />
            {/* {!data.info && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='info' data-validator='notEmpty' className='fv-help-block'>
                  Comments are required
                </div>
              </div>
            )} */}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          {/* <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Select Database Engine</span>

              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Select your app database engine'
              ></i>
            </label>
           
            <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-warning'>
                    <i className='fab fa-amazon text-warning fs-2x'></i>
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>DynamoDB</span>
                  <span className='fs-7 text-muted'>Amazon Fast NoSQL Database</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='databaseSolution'
                  value='DynamoDB'
                  checked={data.tags.databaseSolution === 'DynamoDB'}
                  onChange={() =>
                    updateData({
                      tags: {
                        databaseName: data.tags.databaseName,
                        databaseSolution: 'DynamoDB',
                      },
                    })
                  }
                />
              </span>
            </label>
          </div> */}
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export { Step2 }

