/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { Event } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  event: Event
  avatar: boolean
}

const EventInfoCell: FC<Props> = ({ event, avatar }) => {
  const { setGalleryIndex, setItemIdForUpdate } = useListView()

  const openEditModal = () => {
    // You may need to replace 'eventId' with the actual identifier property of the 'Event' model
    setItemIdForUpdate(event.id);
  }

  return (
    <div className='d-flex align-items-center'>
      {
        avatar ?
          <div onClick={openEditModal} className='symbol symbol-oval symbol-50px overflow-hidden me-3'>
            <a href='#'>
              {event.avatar ? (
                <div className='symbol-label'>
                  <img src={event.avatar} alt={event.name} className='w-100' />
                </div>
              ) : (
                <div
                  className={clsx(
                    'symbol-label fs-3',
                    `bg-light-${event.name}`,
                    `text-${event.name}`
                  )}
                >
                  {event.name?.[0]}
                </div>
              )}
            </a>
          </div>
          :
          <div className='d-flex flex-column'>
            <a href='#' onClick={openEditModal} className='text-gray-800 text-hover-primary mb-1'>
              {event.name}
            </a>
          </div>
      }
    </div>
  )
}

export { EventInfoCell }
