import { FC } from 'react'

type Props = {
  tags?: Array<string>
}

const ClientTwoStepsCell: FC<Props> = ({ tags }) => (
  <> {tags?.map((item, index) => <div className='badge badge-light-info fw-bolder m-1'>{item}</div>)}</>
)

export { ClientTwoStepsCell }
