import { ID } from "../../../helpers"

export interface IAppBasic {
  name: string
  type: 'Activations' | 'Collections' | 'Clients'
  info: string
  date: any
}

export type TAppFramework = 'SAYCHEESE'

export interface IAppParent {
  value: string;
  avatar?: string;
  label?: string;
  color?: string;
  id: ID;
  isFixed?: boolean;
  isDisabled?: boolean;
  startDate: string
}

export interface ICreateAppData {
  name: string
  projectNo?: string
  type: 'Activations' | 'Collections' | 'Clients'
  info: string
  startDate?: any
  endDate?: any
  tags: Array<string>
  parent: Array<IAppParent>
  avatar: any
}

export const defaultCreateAppData: ICreateAppData = {
  name: '',
  projectNo: '',
  type: 'Collections',
  endDate: '',
  info: '',
  parent: [],
  tags: [],
  avatar: null,
}

export type StepProps = {
  data: ICreateAppData,
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void,
  hasError: boolean
}

//name, date, tags, image, id
