const QUERIES = {
  USERS_LIST: 'users-list',
  CONTENTS_LIST: 'users-list',
  TEMPLATES_LIST: 'users-list',
  CLIENTS_LIST: 'users-list',
  EVENTS_LIST: 'users-list',
  CAMPAIGNS_LIST: 'users-list',
  APPS_LIST: 'apps-list'
}

export {QUERIES}
