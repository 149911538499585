import React, {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {eventsColumns} from './columns/_columns'
import {Event} from '../core/_models'
import {EventsListLoading} from '../components/loading/EventsListLoading'
import {EventsListPagination} from '../components/pagination/EventsListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'

const EventsTable = () => {
  const events = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => events, [events])
  const columns = useMemo(() => eventsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_events'
          // className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          className='table fs-6 gy-5 dataTable table-row-bordered table-row-gray-100 align-middle gs-0 '
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Event>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody 
          className='text-gray-600 fw-bold' {...getTableBodyProps()}
          >
            {rows.length > 0 ? (
              rows.map((row: Row<Event>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <EventsListPagination />
      {isLoading && <EventsListLoading />}
    </KTCardBody>
  )
}

export {EventsTable}
