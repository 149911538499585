/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import { Dropdown0 } from '../../../content/dropdown/Dropdown0'
import { StepProps } from '../IAppModels'

interface DropdownOptions {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'campaign', label: 'campaign', color: '#00B8D9', isFixed: true },
  { value: 'client', label: 'client', color: '#00B8D9', isFixed: true },
  { value: 'testing', label: 'testing', color: '#00B8D9', isFixed: true },
  { value: 'SAYCHEESE', label: 'SAYCHEESE', color: 'red', isDisabled: true },
];


const Step3 = ({ data, updateData, hasError }: StepProps) => {
  const [value, setValue] = useState<any>([]);

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center mb-4 form-label fw-bold'>
            <span className='required'>Tags:</span>
            {/* <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your apps framework'
            ></i> */}
          </label>
          {/* end::Label */}
          {/*begin:Option */}

          {/* <div className='mb-10'> */}
            {/* <label className='form-label fw-bold'>Tags:</label> */}
            <div onClick={(event) => {
              event.stopPropagation();
            }}>

              <CreatableSelect
                value={value}
                placeholder="Type to Choose or Create"
                // controlShouldRenderValue={false}
                // closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // menuIsOpen={isOpen}
                // onMenuOpen={()=>{
                //   setIsOpen(true);
                // }}
                onChange={(newValue) => {
                  setValue(newValue)
                  let arr = newValue.map((item)=>item.value);
                  console.log(arr)
                  updateData({tags: arr})
                  // setTimeout(()=>setIsOpen(false),100)
                }}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                // closeMenuOnSelect={false}
                className='text-uppercase fs-8'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    // primary25: 'hotpink',
                    // primary: 'white',
                  },
                })}
                // styles={{
                //   control: (base, state) => ({
                //     ...base,
                //     border: "0 !important",
                //     boxShadow: "0 !important",
                //     "&:hover": {
                //       border: "0 !important"
                //     }}),
                //   singleValue: (base) => ({
                //     ...base,
                //     borderWidth: 5,
                //     padding: 5,
                //     borderRadius: 5,
                //     background: dropdownOptions[2].color,
                //     color: 'white',
                //     display: 'flex',
                //   }),
                // }}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                    // zIndex: 19999,
                    // fontWeight: '500',
                    // borderRadius: 10,
                    // color: state.isSelected ? "#000000" : "#000000",
                    // backgroundColor: state.isSelected ? "#eef3f7" : "#ffffff",
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 10,
                      backgroundColor: '#432cffb8',
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    // fontSize: 10,
                    fontWeight: 'bold',
                    color:'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    // color: 'gray',
                    ':hover': {
                      // backgroundColor: 'yellow',
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}
                // defaultValue={[dropdownOptions[2], dropdownOptions[3]]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={dropdownOptions}
              />
               {!data.tags.length && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='name' data-validator='notEmpty' className='fv-help-block'>
                  Tags required
                </div>
              </div>
            )}
            </div>
          </div>
          {/* <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-warning'>
                  <i className='fab fa-html5 text-warning fs-2x'></i>
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>HTML5</span>
                <span className='fs-7 text-muted'>Base Web Projec</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='radio'
                name='appFramework'
                value='HTML5'
                checked={data.appFramework === 'HTML5'}
                onChange={() => updateData({appFramework: 'HTML5'})}
              />
            </span>
          </label> */}
          {/*end::Option */}

        {/* </div> */}
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { Step3 }
