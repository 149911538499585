import { useListView } from '../../core/ListViewProvider'
import { ContentsListToolbar } from './ContentListToolbar'
import { ContentsListGrouping } from './ContentsListGrouping'
import { ContentsListSearchComponent } from './ContentsListSearchComponent'

const ContentsListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* begin::Card toolbar */}
      <div className='card-toolbar-container d-flex align-items-center'>
        
        <div className='me-3'>
          <ContentsListToolbar />
        </div>

       
        <div className='flex-grow-1'>
          <ContentsListSearchComponent />
        </div>
      </div>
      {selected.length > 0 ? (
          <div className='me-3'>
            <ContentsListGrouping />
          </div>
        ) : (
          <></>
        )}
    </div>
  )
}

export { ContentsListHeader }
