import {useListView} from '../../core/ListViewProvider'
import {AppsListToolbar} from './AppListToolbar'
import {AppsListGrouping} from './AppsListGrouping'
import {AppsListSearchComponent} from './AppsListSearchComponent'

const AppsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <AppsListSearchComponent />
      {/* begin::Card toolbar */}
      {/* <div className='card-toolbar'>
        {selected.length > 0 ? <AppsListGrouping /> : <AppsListToolbar />}
      </div> */}
    </div>
  )
}

export {AppsListHeader}
