import React, { useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { exportSelectedContents } from '../../core/_requests'
import { KTSVG } from '../../../../../../../_metronic/helpers'

const ContentsListGrouping = () => {
  const { selected, clearSelected, setFlagForDelete } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  
  const [isLoading, setIsLoading] = useState(false)
  
  const mutationExport = useMutation(exportSelectedContents, {
    onSuccess: () => {
      // Handle success or any other logic
      clearSelected();
      setIsLoading(false);
    },
    onError: () => {
      // Handle error or any other logic
      setIsLoading(false);
    }
  });
  
  const handleExportClick = async () => {
    setIsLoading(true);
    await mutationExport.mutateAsync(selected);
  }
  
  const handleClearSelected = () => {
    clearSelected();
    setIsLoading(false);
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {selected.length > 0 && (
        <button
          type='button'
          className='btn me-2 svg-icon svg-icon-1 bold svg-icon-danger'
          onClick={handleClearSelected}
        >
          Clear
        </button>
      )}

      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger me-2'
        onClick={() => { setFlagForDelete?.(true) }}
      >
        Archive Selected
      </button>

      <button
        type='button'
        className='btn btn-primary'
        onClick={handleExportClick}
        disabled={isLoading}
      >
        {isLoading ? 'Exporting...' : 'Export Selected'}
      </button>
    </div>
  );
}

export { ContentsListGrouping }
