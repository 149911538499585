import { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { ColumnInstance, Row, useTable } from 'react-table'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { TemplatesListLoading } from '../components/loading/TemplatesListLoading'
import { TemplatesListPagination } from '../components/pagination/TemplatesListPagination'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { Template } from '../core/_models'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { templatesColumns } from './columns/_columns'
import { useListView } from '../core/ListViewProvider'

import '../style.css'



const TemplatesTable = () => {
  const templates = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const { setGalleryIndex, selected, onSelect, setItemIdForUpdate } = useListView();
  const data = useMemo(() => templates, [templates])
  const columns = useMemo(() => templatesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  const { state } = useQueryRequest()

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        {
          state.view !== 'gallery' ?

            <table
              id='kt_table_templates'
              // className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              className='table fs-6 gy-5 dataTable table-row-bordered table-row-gray-100 align-middle gs-0 '
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers?.map((column: ColumnInstance<Template>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody
                className='text-gray-600 fw-bold' {...getTableBodyProps()}
              >
                {rows.length > 0 ? (
                  rows?.map((row: Row<Template>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            :
            <div style={{ marginTop: 10, marginBottom: 40, paddingLeft: "1%", display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {
                templates?.map((item, index) =>
                  <div key={item.id} style={{ position: 'relative', width: '17%', margin: '1%', }}>
                    <a
                      // data-lg-size="1406-1390"
                      data-src={item.original}
                      draggable={false}
                      onClick={() => {
                        if (selected.length) {
                          onSelect(item.id)
                        }
                        else {
                          setItemIdForUpdate(item.id)
                          // setGalleryIndex(index)
                        }

                      }}
                    >
                      {!item.video ? (
                        <img
                          className={`img-thumbnail shadow-none ${selected.includes(item.id) ? 'selected' : ''}`}
                          style={{ width: "100%", scrollMarginInlineEnd: 0 }}
                          src={item.avatar + "&auto=format&fit=crop&w=240&q=80"}
                        />
                      ) :
                        item.video &&
                        <div className='symbol-label' style={{}}>
                          <video
                            className={`img-thumbnail shadow-none ${selected.includes(item.id) ? 'selected' : ''}`}
                            src={item.video}
                            // alt='Video Alt Text'
                            controls
                            controlsList="nodownload"
                            style={{ width: "100%", scrollMarginInlineEnd: 0 }}
                          />

                        </div>

                      }
                    </a>
                    <div
                      className='form-check form-check-sm form-check-custom form-check-solid'
                      style={{ position: 'absolute', top: '10%', left: 20 }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        data-kt-check={selected.includes(item.id)}
                        data-kt-check-target='#kt_table_contents .form-check-input'
                        checked={selected.includes(item.id)}
                        onChange={() => onSelect(item.id)}
                      />
                    </div>
                  </div>
                )
              }
            </div>
        }
      </div>
      <TemplatesListPagination />
      {isLoading && <TemplatesListLoading />}
    </KTCardBody >
  )
}

export { TemplatesTable }

