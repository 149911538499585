import {useQuery} from 'react-query'
import {SessionEditModalForm} from './SessionEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getSessionById} from '../core/_requests'

const SessionEditModalFormWrapper = () => {
  const {sessionIdForUpdate, setSessionIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(sessionIdForUpdate)
  const {
    isLoading,
    data: session,
    error,
  } = useQuery(
    `${QUERIES.CONTENTS_LIST}-session-${sessionIdForUpdate}`,
    () => {
      return getSessionById(sessionIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setSessionIdForUpdate && setSessionIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!sessionIdForUpdate) {
    return <SessionEditModalForm isSessionLoading={isLoading} session={{id: undefined}} />
  }

  if (!isLoading && !error && session) {
    return <SessionEditModalForm isSessionLoading={isLoading} session={session} />
  }

  return null
}

export {SessionEditModalFormWrapper}
