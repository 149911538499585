import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useParams } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DisableSidebar } from '../../_metronic/layout/core'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import EventsPage from '../modules/apps/event-management/EventsPage'
import ClientsPage from '../modules/apps/client-management/ClientsPage'
import ContentsPage from '../modules/apps/content-management/ContentsPage'
import TemplatesPage from '../modules/apps/template-management/TemplatesPage'
import { openIframeInNewTab } from '../../jsFunctions'
import { Card5 } from '../../_metronic/partials/content/cards/Card5'
import { PageTitle } from '../../_metronic/layout/core'
import { PageLink } from '../../_metronic/layout/core'

const eventsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Activation Management',
    path: '/apps/event-management/events',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Report',
    path: '',
    isSeparator: true,
    isActive: true,
  },
]


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  //hunnain_sc_edits
  const CampaignsPage = lazy(() => import('../modules/apps/campaign-management/CampaignsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/client-management/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/campaign-management/*'
          element={
            <SuspensedView>
              <CampaignsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/content-management/*'
          element={
            <SuspensedView>
              <ContentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/template-management/*'
          element={
            <SuspensedView>
              <TemplatesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/event-management/*'
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/event-management/:eventName/report'
          element={
            <>
              <PageTitle breadcrumbs={eventsBreadcrumbs}>Activation Report</PageTitle>
              {/* <EventsListWrapper /> */}
              <EventReportPage />
            </>


          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

const EventReportPage: FC = () => {
  const { eventName } = useParams<{ eventName: string }>();

  // Extracting link and name from query parameters
  const searchParams = new URLSearchParams(window.location.search);
  const link = searchParams.get('link') || '';
  const name = searchParams.get('name') || '';

  return (
    // <div>
    //   <h1>Event Report</h1>
    //   <p>Event Name: {eventName}</p>

    //   <iframe title="Event Report" src={link} width="100%" height="500px" frameBorder="0"></iframe>
    // </div>
    <div>

      {/* <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
        className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0">
        <h1 className="d-flex flex-column text-dark fw-bolder my-0 fs-1">{eventName}</h1>
        <ul className="breadcrumb breadcrumb-dot fw-bold fs-base my-1"><li className="breadcrumb-item text-muted">
          <a className="text-muted" href="/apps/user-management/users">Event</a></li>
          <li className="breadcrumb-item text-dark"> Report</li>
        </ul>
      </div> */}


      <div className="card">
        <div className='d-flex flex-wrap flex-stack mb-6 mt-6 m-6'>
          <iframe title="Event Report" src={link} width="100%" height="1000vh" frameBorder="0"></iframe>
        </div>
      </div>
    </div>
  );
};

export { PrivateRoutes }
