import axios from 'axios';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, query as firebaseQuery, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string): Promise<any> {
  const db = getFirestore();
  const auth = getAuth();
  return new Promise(resolve => signInWithEmailAndPassword(
    auth,
    email,
    password
  ).then(response => {
    const jsonStr = JSON.stringify(response.user);
    const user = JSON.parse(jsonStr);
    console.log("\n\n", { api_token: user.uid, refreshToken: user.stsTokenManager.refreshToken }, "\n\n")
    // return { api_token: user.stsTokenManager.accessToken, refreshToken: user.stsTokenManager.refreshToken };
    resolve({ data: { api_token: user.uid, refreshToken: user.stsTokenManager.refreshToken } })

  }).catch(error => {
    console.error(error)
    resolve(error)
  }))

  // return axios.post<AuthModel>(LOGIN_URL, {
  //   email,
  //   password,
  // })

}


export function createPassword(email: string, password: string): Promise<any> {
  const db = getFirestore();
  const auth = getAuth();
  return new Promise(resolve => signInWithEmailAndPassword(
    auth,
    email,
    password
  ).then(response => {
    const jsonStr = JSON.stringify(response.user);
    const user = JSON.parse(jsonStr);
    console.log("\n\n", { api_token: user.uid, refreshToken: user.stsTokenManager.refreshToken }, "\n\n")
    // return { api_token: user.stsTokenManager.accessToken, refreshToken: user.stsTokenManager.refreshToken };
    resolve({ data: { api_token: user.uid, refreshToken: user.stsTokenManager.refreshToken } })


  }).catch(error => {
    resolve(error)
    console.error(error)
  }))
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  prefix: string,
  lastName: string,
  password: string,
  password_confirmation: string
): Promise<any> {

  const auth = getAuth();

  const db = getFirestore()
  let q = firebaseQuery(collection(db, "_users"))
  q = firebaseQuery(collection(db, "_users"), where("email", '==', email))

  console.log("Function register:")

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {
        console.log(querySnapshot.size)
        if (!querySnapshot.size) {
          console.log("Invitation does not exist.")
          resolve("Invitation does not exist.")
        }
        else {
          querySnapshot.forEach(document => {
            let user = document.data()
            console.log(user.password)
            if (!user.password) {
              const panelUser = doc(db, "_users", user.id)
              createUserWithEmailAndPassword(
                auth,
                email,
                password
              )
                .then(response => {
                  const jsonStr = JSON.stringify(response.user);
                  const user = JSON.parse(jsonStr);
                  setDoc(panelUser, { password, uid: user.uid, name: firstName + ' ' + prefix + ' ' + lastName, firstName: firstName, prefix: prefix ? prefix : "", lastName: lastName, }, { merge: true }).then(() => {
                    resolve({ data: { api_token: user.uid, refreshToken: user.stsTokenManager.refreshToken } })
                  })

                }).catch(error => {
                  resolve({ error: error.message })
                  // resolve(error)
                })

            }
            else {
              resolve({ error: "Unauthorized attempt." })
            }
          })
        }

      }).catch((error) => {
        resolve({ error: error.message })
        console.log(error.message)
      })
  )
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{ result: string }>("https://us-central1-s4ych33se-memorymakers.cloudfunctions.net/resetPassword?email=" + email)
}

export function getUserByToken(token: string): Promise<any> {
  const db = getFirestore();
  const auth = getAuth();


  console.log("token ", token)

  // return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
  //   api_token: token,
  // })

  const q = query(collection(db, '_users'), where('uid', '==', token));
  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {


        console.log("query size ", querySnapshot.size)

        querySnapshot.forEach(doc => {
          const user = doc.data();
          console.log("user ", user)
          resolve({
            data: {
              //
              id: user.uid,
              username: user.name ? user.name : "Guest",
              password: user.password,
              email: user.email,
              first_name: user.first_name ? user.first_name : "Guest",
              last_name: user.last_name ? user.last_name : "Guest",
              fullname: user.fullname ? user.fullname : "Guest",
              occupation: user.occupation ? user.occupation : "N/A",
              companyName: user.companyName ? user.companyName : "N/A",
              phone: user.companyName ? user.companyName : "N/A",
              roles: [0],
              pic: user.image ? user.image : "N/A",
              language: 'en',
              timeZone: user.timeZone ? user.timeZone : "N/A",
              website: user.website ? user.website : "N/A",
              emailSettings: {
                emailNotification: true,
                sendCopyToPersonalEmail: true,
                activityRelatesEmail: {
                  youHaveNewNotifications: true,
                  youAreSentADirectMessage: true,
                  someoneAddsYouAsAsAConnection: true,
                  uponNewOrder: true,
                  newMembershipApproval: true,
                  memberRegistration: true,
                },
                updatesFromKeenthemes: {
                  newsAboutKeenthemesProductsAndFeatureUpdates: true,
                  tipsOnGettingMoreOutOfKeen: true,
                  thingsYouMissedSindeYouLastLoggedIntoKeen: true,
                  newsAboutStartOnPartnerProductsAndOtherServices: true,
                  tipsOnStartBusinessProducts: true
                }
              },
              auth: {
                api_token: true,
                refreshToken: true
              },
              communication: {
                email: true,
                sms: true,
                phone: true,
              },
              address: {
                addressLine: "N/A",
                city: "N/A",
                state: "N/A",
                postCode: "N/A"
              },
              socialNetworks: {
                linkedIn: "https://linkedin.com",
                facebook: "https://facebook.com",
                twitter: "https://linkedin.com",
                instagram: "https://linkedin.com",
              }
            }
          });
        });
      })
      .catch(error => {
        console.log("error ", error)
        resolve(error)
      }))

  // const db = getFirestore();
  // const auth = getAuth();
  // return new Promise(resolve => signInWithEmailAndPassword(
  //     auth,
  //     email,
  //     password
  //   ).then(response => {
  //     const jsonStr = JSON.stringify(response.user);
  //     const user = JSON.parse(jsonStr);
  //     console.log("\n\n",{ api_token: user.stsTokenManager.accessToken, refreshToken: user.stsTokenManager.refreshToken },"\n\n")
  //     // return { api_token: user.stsTokenManager.accessToken, refreshToken: user.stsTokenManager.refreshToken };
  //     resolve({data: {api_token: user.stsTokenManager.accessToken, refreshToken: user.stsTokenManager.refreshToken}})


  //   }).catch(error => {
  //     resolve(error)
  //     console.error(error)
  //   }))

}
