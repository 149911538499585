import { useListView } from '../../core/ListViewProvider'
import { TemplatesListToolbar } from './TemplateListToolbar'
import { TemplatesListGrouping } from './TemplatesListGrouping'
import { TemplatesListSearchComponent } from './TemplatesListSearchComponent'

const TemplatesListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* begin::Card toolbar */}
      <div className='card-toolbar-container d-flex align-items-center'>

        <div className='me-3'>
          <TemplatesListToolbar />
        </div>


        <div className='flex-grow-1'>
          <TemplatesListSearchComponent />
        </div>
      </div>
      {selected.length > 0 ? (
        <div className='me-3'>
          <TemplatesListGrouping />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export { TemplatesListHeader }
