/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Client } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  client: Client
  avatar: boolean
}

const ClientInfoCell: FC<Props> = ({ client, avatar }) => {
  const { setItemIdForUpdate } = useListView()

  const openEditModal = () => {
    setItemIdForUpdate(client.id);
  }

  return (
    <div className='d-flex align-items-center'>
      {
        avatar ?
          <div onClick={openEditModal} className='symbol symbol-oval symbol-50px overflow-hidden me-3'>
            <a href='#'>
              {client.avatar ? (
                <div className='symbol-label'>
                  <img src={client.avatar} alt={client.name} className='w-100' />
                </div>
              ) : (
                <div
                  className={clsx(
                    'symbol-label fs-3',
                    `bg-light-${client.name}`,
                    `text-${client.name}`
                  )}
                >
                  {client.name?.[0]}
                </div>
              )}
            </a>
          </div>
          :
          <div className='d-flex flex-column'>
            <a href='#' onClick={openEditModal} className='text-gray-800 text-hover-primary mb-1'>
              {client.name}
            </a>
          </div>
      }
    </div>
  )
}

export { ClientInfoCell }

