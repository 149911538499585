import { FC } from 'react'

type Props = {
  value?: string
  text?: string
}

const TemplateEnabledDisabled: FC<Props> = ({ value, text }) => (
  <div className={`badge ${value=="true"? "badge-light-success":"badge-light-danger"} fw-bolder m-1`}>{text}</div>
)

export { TemplateEnabledDisabled }
