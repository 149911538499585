import { FC } from 'react'

type Props = {
  tags?: Array<string>
}

const ContentTwoStepsCell: FC<Props> = ({ tags }) => (
  <> {tags?.map((item, index) => <div
    
    onClick={()=>{
      
        // Open the URL when the div is clicked
    
       if(item) {
        window.open("https://yourstorywall.com/content/"+item, '_blank');
       }
    }}
    className='badge badge-light-info fw-bolder m-1'>{item}</div>)}</>
)

export { ContentTwoStepsCell }
