import { FC } from 'react';
import { isURL } from '../../../../../../../jsFunctions';

type Props = {
  value?: string;
  url?: string;
};

const EventEnabledDisabled: FC<Props> = ({ value, url }) => {
  const handleClick = () => {
    // Open the URL when the div is clicked

    if(!url) {
      alert("No Url")
    }
    else {
      var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (urlRegex.test(url)) {
        window.open(url, '_blank');
      }
      else {
        alert("Invalid Url " + url)
      }
    }

    
  };

  return (
    <div
      className={`badge ${value === 'true' ? 'badge-light-success' : 'badge-light-danger'} fw-bolder m-1`}
      onClick={handleClick}
      style={{ cursor: url ? 'pointer' : 'default' }}
    >
      {value === 'true' ? 'Enabled' : 'Disabled'}
    </div>
  );
};

export { EventEnabledDisabled };
