import {ID, Response} from '../../../../../../_metronic/helpers'
export type Content = {
  id?: string
  name?: string
  avatar?: any
  video?: any
  original?: any
  email?: string
  position?: string
  role?: string
  prints?: number,
  mails?: number,
  emails?: Array<string>,
  sessionNumber?: string
  session?: string
  template?: string
  photoroom?: boolean
  consent?: boolean
  isDooh?: boolean
  isLocked?: boolean
  templateType?: string
  parent?: any
  info?: string
  tags?: Array<string>
  qrCodes?: Array<string>
  date?: string
  projectNo?: string
  sentMailsCount?: number
  size?: number,
  dimensions?: string
  dateCreated?: string
  updatedOn?: string
  activations?: any
  collections?: any
  memoryMakers?: any
  dooh?: boolean
  templates?: any
}

export type ContentsQueryResponse = Response<Array<Content>>

export const initialContent: Content = {
  avatar: 'avatars/300-6.jpg',
  original: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
  info: '',
}


export type Session = {
  id?: string
  name?: string
  avatar?: any
  original?: any
  email?: string
  position?: string
  role?: string
  prints?: number,
  mails?: number,
  emails?: Array<string>,
  sessionNumber?: string
  session?: string
  template?: string
  photoroom?: boolean
  consent?: boolean
  isDooh?: boolean
  isLocked?: boolean
  type?: string
  parent?: any
  info?: string
  tags?: Array<string>
  qrCodes?: Array<string>
  date?: string
  projectNo?: string
  dimensions?: string
  dateCreated?: string
  updatedOn?: string
  activations?: any
  collections?: any
  memoryMakers?: any
  microsite?: boolean
  dooh?: boolean
  templates?: any
}

export type SessionsQueryResponse = Response<Array<Session>>

export const initialSession: Session = {
  avatar: 'avatars/300-6.jpg',
  original: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
  info: '',
}
